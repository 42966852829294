var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"w-full flex flex-wrap justify-center",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-2xl sm:text-3xl md:text-4xl font-extrabold flex"},[_c('h2',{staticClass:"t-title-2"},[_vm._v("\n          "+_vm._s(_vm.item.product.name)+"\n        ")])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Datos de cuenta")]),_vm._v(" "),_c('div',{staticClass:"t-section-content py-5"},[_c('div',{staticClass:"t-input-group"},[_c('label',{staticClass:"t-label",attrs:{"for":"email"}},[_vm._v("Email")]),_vm._v(" "),_c('div',{staticClass:"flex-1 cursor-not-allowed"},[_c('div',{staticClass:"w-100 text-left t-form"},[_vm._v("\n                  "+_vm._s(_vm.baseData.email)+"\n                ")])])])])])]),_vm._v(" "),(_vm.loadedData)?_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Datos personales")]),_vm._v(" "),_c('div',{staticClass:"t-section-content py-5"},[_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.name && !_vm.$v.baseData.name.$invalid) ||
                      !_vm.$v.baseData.name
                  ),"text":'Nombre',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.name.$model),expression:"$v.baseData.name.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.name.$invalid && _vm.showErrors ? 'invalid' : ''
                  ],attrs:{"type":"text","placeholder":"Nombre"},domProps:{"value":(_vm.$v.baseData.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Nombre requerido\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.first_name &&
                      !_vm.$v.baseData.first_name.$invalid) ||
                      !_vm.$v.baseData.first_name
                  ),"text":'Apellido paterno',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.first_name.$model),expression:"$v.baseData.first_name.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.first_name.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","placeholder":"Apellido paterno"},domProps:{"value":(_vm.$v.baseData.first_name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.first_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.first_name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Apellido requerido\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.last_name &&
                      !_vm.$v.baseData.last_name.$invalid) ||
                      !_vm.$v.baseData.last_name
                  ),"text":'A. Materno',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.last_name.$model),expression:"$v.baseData.last_name.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.last_name.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","placeholder":"Apellido materno"},domProps:{"value":(_vm.$v.baseData.last_name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.last_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.last_name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Apellido requerido\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.phone_number &&
                      !_vm.$v.baseData.phone_number.$invalid) ||
                      !_vm.$v.baseData.phone_number
                  ),"text":'Teléfono',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.phone_number.$model),expression:"$v.baseData.phone_number.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.phone_number.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"placeholder":"Escribe 10 números","type":"number","minlength":"10","maxlength":"10","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"},domProps:{"value":(_vm.$v.baseData.phone_number.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.phone_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.phone_number.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Número teléfonico requerido\n                ")]):_vm._e(),_vm._v(" "),(!_vm.$v.baseData.phone_number.numeric && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Digita únicamente números.\n                ")]):_vm._e(),_vm._v(" "),(
                    (!_vm.$v.baseData.phone_number.minLengthValue &&
                      _vm.showErrors) ||
                      (!_vm.$v.baseData.phone_number.maxLengthValue && _vm.showErrors)
                  )?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Sólo digita 10 números sin espacios.\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.born_date &&
                      !_vm.$v.baseData.born_date.$invalid) ||
                      !_vm.$v.baseData.born_date
                  ),"text":'Fecha de nacimiento',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.born_date.$model),expression:"$v.baseData.born_date.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.born_date.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"date","maxlength":"3","placeholder":"3 carácteres alfanumericos"},domProps:{"value":(_vm.$v.baseData.born_date.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.born_date, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.born_date.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Campo requerido.\n                ")]):_vm._e(),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.born_date.isMinValidAge)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Debes ser mayor de 18 años para comprar un seguro.\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.gender && !_vm.$v.baseData.gender.$invalid) ||
                      !_vm.$v.baseData.gender
                  ),"text":'Sexo',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('c-autocomplete',{staticClass:"t-form",attrs:{"api_resource":"gender","data":[
                    { id: 0, name: 'Femenino' },
                    { id: 1, name: 'Masculino' }
                  ],"httpConnector":_vm.$http},model:{value:(_vm.$v.baseData.gender.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.gender, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.baseData.gender.$model"}}),_vm._v(" "),(!_vm.$v.baseData.gender.id.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Género requerido\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.rfc && !_vm.$v.baseData.rfc.$invalid) ||
                      !_vm.$v.baseData.rfc
                  ),"text":'RFC',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.rfc.$model),expression:"$v.baseData.rfc.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[_vm.$v.baseData.rfc.$invalid ? 'invalid' : ''],attrs:{"type":"text","maxlength":"10","placeholder":"10 carácteres alfanumericos"},domProps:{"value":(_vm.$v.baseData.rfc.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.rfc, "$model", $event.target.value.trim())},function($event){_vm.$v.baseData.rfc.$model = String(
                      _vm.$v.baseData.rfc.$model
                    ).toUpperCase()}],"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.rfc.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Campo requerido.\n                ")]):_vm._e(),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.rfc.rfc)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  El RFC debe ser uno válido.\n                ")]):_vm._e(),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.rfc.isValidDt)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Tu fecha del RFC debe ser igual a tu fecha de nacimiento\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.homoclave &&
                      !_vm.$v.baseData.homoclave.$invalid) ||
                      !_vm.$v.baseData.homoclave
                  ),"text":'Homoclave',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 relative"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.homoclave.$model),expression:"$v.baseData.homoclave.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[_vm.$v.baseData.homoclave.$invalid ? 'invalid' : ''],attrs:{"type":"text","maxlength":"3","placeholder":"3 carácteres alfanumericos"},domProps:{"value":(_vm.$v.baseData.homoclave.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.homoclave, "$model", $event.target.value.trim())},function($event){_vm.$v.baseData.homoclave.$model = String(
                      _vm.$v.baseData.homoclave.$model
                    ).toUpperCase()}],"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.homoclave.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Campo requerido.\n                ")]):_vm._e(),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.homoclave.minHomoLengthValue)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  La homoclave debe contener 3 caracteres.\n                ")]):_vm._e()])],1)])])]):_vm._e(),_vm._v(" "),(_vm.loadedData)?_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Dirección")]),_vm._v(" "),_c('div',{staticClass:"t-section-content py-5"},[_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.zip_code &&
                      !_vm.$v.baseData.zip_code.$invalid) ||
                      !_vm.$v.baseData.zip_code
                  ),"text":'C.P.',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('c-autocomplete',{staticClass:"t-form h-max-10",class:[
                    _vm.$v.baseData.zip_code.id.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"data":_vm.formDataOptions.zip_code,"api_resource":"catalog/zip-code/","httpConnector":_vm.$http},on:{"change":_vm.refreshAddressInfo},model:{value:(_vm.$v.baseData.zip_code.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.zip_code, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.baseData.zip_code.$model"}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.zip_code.id.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Código postal requerido.\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.country &&
                      !_vm.$v.baseData.country.id.$invalid) ||
                      !_vm.$v.baseData.country
                  ),"text":'País',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('v-select',{staticClass:"t-form h-max-10",class:[
                    _vm.$v.baseData.country.id.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","disabled":"","options":[_vm.formDataOptions.country],"index":"id","label":"name","return-object":""},model:{value:(_vm.$v.baseData.country.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.country, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.baseData.country.$model"}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.country.id.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  País requerido.\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.state && !_vm.$v.baseData.state.id.$invalid) ||
                      !_vm.$v.baseData.state
                  ),"text":'Estado',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('v-select',{staticClass:"t-form h-max-10",class:[
                    _vm.$v.baseData.state.id.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","disabled":"","options":[_vm.formDataOptions.state],"index":"id","label":"name","return-object":""},model:{value:(_vm.$v.baseData.state.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.state, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.baseData.state.$model"}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.state.id.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Estado requerido.\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.town_council &&
                      !_vm.$v.baseData.town_council.id.$invalid) ||
                      !_vm.$v.baseData.town_council
                  ),"text":'Municipio',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('v-select',{staticClass:"t-form h-max-10",class:[
                    _vm.$v.baseData.town_council.id.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","disabled":"","options":[_vm.formDataOptions.town_council],"index":"id","label":"name","return-object":""},model:{value:(_vm.$v.baseData.town_council.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.town_council, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.baseData.town_council.$model"}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.town_council.id.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Municipio requerido.\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.colony && !_vm.$v.baseData.colony.id.$invalid) ||
                      !_vm.$v.baseData.colony
                  ),"text":'Colonia',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('v-select',{staticClass:"t-form h-max-10",class:[
                    _vm.$v.baseData.colony.id.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","disabled":"","options":[_vm.formDataOptions.colony],"index":"id","label":"name","return-object":""},model:{value:(_vm.$v.baseData.colony.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.colony, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.baseData.colony.$model"}}),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.colony.id.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Colonia requerida.\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.street && !_vm.$v.baseData.street.$invalid) ||
                      !_vm.$v.baseData.street
                  ),"text":'Calle y número',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.street.$model),expression:"$v.baseData.street.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.street.$invalid && _vm.showErrors ? 'invalid' : ''
                  ],attrs:{"type":"text","placeholder":"Calle"},domProps:{"value":(_vm.$v.baseData.street.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.street, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.num_ext.$model),expression:"$v.baseData.num_ext.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.num_ext.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","placeholder":"No. ext."},domProps:{"value":(_vm.$v.baseData.num_ext.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.num_ext, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.street.required)?_c('div',{staticClass:"t-form-error w-full"},[_vm._v("\n                Calle requerida.\n              ")]):_vm._e(),_vm._v(" "),(_vm.showErrors && !_vm.$v.baseData.num_ext.required)?_c('div',{staticClass:"t-form-error w-full"},[_vm._v("\n                Número exterior del domicilio requerido.\n              ")]):_vm._e()],1)])])]):_vm._e(),_vm._v(" "),(_vm.showAPIErrors)?_c('div',{staticClass:"w-full flex flex-wrap justify-end"},[_c('div',{staticClass:"t-form-error block"},_vm._l((_vm.ApiErrorMessages),function(error,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(error.title))])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-wrap justify-end mt-5 mb-10"},[(_vm.showErrors && _vm.$v.baseData.$invalid)?_c('div',{staticClass:"\n            w-full\n            rounded-md\n            px-5\n            py-2\n            border-2 border-red-700\n            bg-red-50\n            text-red-700\n            my-3\n          "},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v("\n          Algunos campos requieren de tu atención antes de poder continuar.\n        ")],1):_vm._e(),_vm._v(" "),_c('button',{staticClass:"\n            bg-special-3\n            h-10\n            px-10\n            rounded-md\n            text-primary text-2xl\n            w-full\n            sm:w-auto\n          ",attrs:{"type":"submit"}},[_vm._v("\n          Confirmar\n        ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-secondary mb-5 rounded-md px-5 y-2"},[_c('p',{staticClass:"t-alert success"},[_vm._v("\n          Usaremos esta información para generar el seguro, es necesario que\n          revises los requisitos y que confirmes que la información es\n          correcta.\n        ")]),_vm._v(" "),_c('p',{staticClass:"t-alert success"},[_vm._v("\n          Después de confirmar, no se podrá realizar ningún cambio.\n        ")])])}]

export { render, staticRenderFns }