<template>
  <form @submit.prevent="onSubmit" class="w-full flex flex-wrap justify-center">
    <div class="container">
      <div class="text-2xl sm:text-3xl md:text-4xl font-extrabold flex">
        <h2 class="t-title-2">
          {{ item.product.name }}
        </h2>
      </div>
      <div class="text-secondary mb-5 rounded-md px-5 y-2">
        <p class="t-alert success">
          Usaremos esta información para generar el seguro, es necesario que
          revises los requisitos y que confirmes que la información es correcta.
        </p>
        <p class="t-alert success">
          Después de confirmar, no se podrá realizar ningún cambio.
        </p>
      </div>
      <div class="w-full px-5">
        <div class="t-form-section">
          <div class="t-section-title py-0">Datos de cuenta</div>
          <div class="t-section-content py-5">
            <div class="t-input-group">
              <label for="email" class="t-label">Email</label>
              <div class="flex-1 cursor-not-allowed">
                <div class="w-100 text-left t-form">
                  {{ baseData.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loadedData" class="w-full px-5">
        <div class="t-form-section">
          <div class="t-section-title py-0">Datos de tu mascota</div>
          <div class="t-section-content py-5">
            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_name && !$v.baseData.pet_name.$invalid) ||
                      !$v.baseData.pet_name
                  )
                "
                :text="'Mi mascota se llama'"
                :required="true"
              />
              <div class="flex-1 max-w-full">
                <input
                  v-model.trim="$v.baseData.pet_name.$model"
                  type="text"
                  placeholder="Nombre"
                  class="t-form"
                  :class="[
                    $v.baseData.pet_name.$invalid && showErrors ? 'invalid' : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="!$v.baseData.pet_name.required && showErrors"
                >
                  ¿Cómo se llama tu mascota?
                </div>
              </div>
            </div>


            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_age && !$v.baseData.pet_age.$invalid) ||
                      !$v.baseData.pet_age
                  )
                "
                :text="'Edad'"
                :required="true"
              />
              <div class="flex-1 max-w-full">
                <input
                  disabled
                  v-model.trim="$v.baseData.pet_age.$model"
                  type="text"
                  min="1"
                  placeholder="Edad"
                  class="t-form"
                  maxlength="2"
                  :class="[
                    $v.baseData.pet_age.$invalid && showErrors ? 'invalid' : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="!$v.baseData.pet_age.required && showErrors"
                >
                  Te falto escribir la edad.
                </div>
                <div
                  class="t-form-error"
                  v-if="!$v.baseData.pet_age.intRegexPositive && showErrors"
                >
                  La edad debe ser > 0.
                </div>
              </div>
            </div>

            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_color &&
                      !$v.baseData.pet_color.$invalid) ||
                      !$v.baseData.pet_color
                  )
                "
                :text="'¿De qué color es tu mascota?'"
                :required="true"
              />
              <div class="flex-1 max-w-full">
                <input
                  v-model.trim="$v.baseData.pet_color.$model"
                  type="text"
                  placeholder="Color"
                  class="t-form"
                  :class="[
                    $v.baseData.pet_color.$invalid && showErrors
                      ? 'invalid'
                      : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="!$v.baseData.pet_color.required && showErrors"
                >
                  Campo requerido.
                </div>
              </div>
            </div>
            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_nose_color &&
                      !$v.baseData.pet_nose_color.$invalid) ||
                      !$v.baseData.pet_nose_color
                  )
                "
                :text="'¿De qué color es su naríz?'"
                :required="true"
              />
              <div class="flex-1 text-center">
                <input
                  v-model.trim="baseData.pet_nose_color"
                  type="text"
                  name="pet_nose_color"
                  class="t-form"
                  :class="[
                    $v.baseData.pet_nose_color.$invalid && showErrors
                      ? 'invalid'
                      : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="showErrors && !$v.baseData.pet_nose_color.required"
                >
                  Campo requerido.
                </div>
              </div>
            </div>
            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_breed &&
                      !$v.baseData.pet_breed.$invalid) ||
                      !$v.baseData.pet_breed
                  )
                "
                :text="'¿De qué raza es?'"
                :required="true"
              />
              <div class="flex-1 text-center">
                <input
                  v-model.trim="baseData.pet_breed"
                  type="text"
                  name="pet_breed"
                  class="t-form"
                  :class="[
                    $v.baseData.pet_breed.$invalid && showErrors
                      ? 'invalid'
                      : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="showErrors && !$v.baseData.pet_breed.required"
                >
                  Campo requerido.
                </div>
              </div>
            </div>
            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_eye_color &&
                      !$v.baseData.pet_eye_color.$invalid) ||
                      !$v.baseData.pet_eye_color
                  )
                "
                :text="'¿Qué color son sus ojos?'"
                :required="true"
              />
              <div class="flex-1 text-center">
                <input
                  v-model.trim="baseData.pet_eye_color"
                  type="text"
                  name="pet_eye_color"
                  class="t-form"
                  :class="[
                    $v.baseData.pet_eye_color.$invalid && showErrors
                      ? 'invalid'
                      : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="showErrors && !$v.baseData.pet_eye_color.required"
                >
                  Campo requerido.
                </div>
              </div>
            </div>
            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_details &&
                      !$v.baseData.pet_details.$invalid) ||
                      !$v.baseData.pet_details
                  )
                "
                :text="'¿Qué carácteristicas particulares tiene?'"
                :required="true"
              />
              
              <div class="flex-1 text-center">
                <textarea
                  v-model.trim="baseData.pet_details"
                  type="text"
                  rows="5"
                  name="pet_details"
                  class="t-form"
                  :class="[
                    $v.baseData.pet_details.$invalid && showErrors
                      ? 'invalid'
                      : ''
                  ]"
                />
                <div
                  class="t-form-error"
                  v-if="showErrors && !$v.baseData.pet_details.required"
                >
                  Campo requerido.
                </div>
              </div>

            </div>



            <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_type && !$v.baseData.pet_type.$invalid) ||
                      !$v.baseData.pet_type
                  )
                "
                :text="'¿Qué tipo de mascota es?'"
                :required="true"
              />
              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.pet_type.$model = '1'"
                >
                  <label for="">Perro</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.pet_type.$model"
                    name="pet_type"
                    value="Perro"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.pet_type.$model = '2'"
                >
                  <label for="">Gato</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.pet_type.$model"
                    name="pet_type"
                    value="Gato"
                  />
                </div>
              </div>





              <div
                v-if="showErrors && !$v.baseData.pet_type.required"
                class="t-form-error"
              >
                Necesitamos saber el tipo de mascota que tienes.
              </div>
            </div>



            <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.pet_gender &&
                      !$v.baseData.pet_gender.$invalid) ||
                      !$v.baseData.pet_gender
                  )
                "
                :text="'¿Qué es?'"
                :required="true"
              />
              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.pet_gender.$model = '1'"
                >
                  <label for="">Macho</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.pet_gender.$model"
                    name="pet_gender"
                    value="Macho"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.pet_gender.$model = '2'"
                >
                  <label for="">Hembra</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.pet_gender.$model"
                    name="pet_gender"
                    value="Hembra"
                  />
                </div>
              </div>
              <div
                v-if="showErrors && !$v.baseData.pet_gender.required"
                class="t-form-error"
              >
                Necesitamos saber si es macho o hembra.
              </div>
            </div>







            <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.has_pedigree &&
                      !$v.baseData.has_pedigree.$invalid) ||
                      !$v.baseData.has_pedigree
                  )
                "
                :text="'¿Tiene pedigre?'"
                :required="true"
              />

              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.has_pedigree.$model = 'Sí'"
                >
                  <label for="">Sí</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.has_pedigree.$model"
                    name="has_pedigree"
                    value="Sí"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.has_pedigree.$model = 'No'"
                >
                  <label for="">No</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.has_pedigree.$model"
                    name="has_pedigree"
                    value="No"
                  />
                </div>
              </div>
              <div
                v-if="showErrors && !$v.baseData.has_pedigree.required"
                class="t-form-error"
              >
                Necesitamos saber si tiene pedigre.
              </div>
            </div>





            <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.has_chip &&
                      !$v.baseData.has_chip.$invalid) ||
                      !$v.baseData.has_chip
                  )
                "
                :text="'¿Tiene Chip?'"
                :required="true"
              />
              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.has_chip.$model = 'Sí'"
                >
                  <label for="">Sí</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.has_chip.$model"
                    name="has_chip"
                    value="Sí"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.has_chip.$model = 'No'"
                >
                  <label for="">No</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.has_chip.$model"
                    name="has_chip"
                    value="No"
                  />
                </div>
              </div>
              <div
                v-if="showErrors && !$v.baseData.has_chip.required"
                class="t-form-error"
              >
                Necesitamos saber si tiene Chip.
              </div>
            </div>
          </div>
        </div>
      </div>




      <div class="w-full flex flex-wrap justify-end mt-5 mb-10">
        <div
          v-if="showErrors && $v.baseData.$invalid"
          class="
            w-full
            rounded-md
            px-5
            py-2
            border-2 border-red-700
            bg-red-50
            text-red-700
            my-3
          "
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="fa-lg"
          />
          Algunos campos requieren de tu atención antes de poder continuar.
        </div>
        <button
          type="button"
          class="bg-danger text-white px-5 py-1 rounded-md"
          @click="$emit('close')"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="
            bg-special-3
            h-10
            px-10
            rounded-md
            text-primary-dark text-xl
            w-full
            sm:w-auto
          "
        >
          Confirmar
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { isPositiveInteger } from "../../constants/config";
import { scrollTo } from "../../utils/index";
export default {
  data: function() {
    return {
      showErrors: false,
      loadedData: false,
      baseData: {},
      formData: {
        pet_name: "",
        pet_age: null,
        pet_color: "",
        pet_nose_color: "",
        pet_breed: "",
        pet_eye_color: "",
        pet_details: "",
        pet_type: "",
        pet_gender: "",
        has_pedigree: "",
        has_chip: "",
      },
      formDataOptions: {
        gender: [],
        civil_status: [],
        country: [],
        zip_code: [],
        state: [],
        town_council: [],
        colony: []
      }
    };
  },
  props: {
    data: {},
    item: {},
    itemId: {},
    lastQuoteData: {}
  },
  validations() {
    return {
      baseData: {

        pet_name: {
          required
        },
        pet_age: {
          required,
          isPositiveInteger,
        },
        pet_color: {
          required
        },
        pet_nose_color: {
          required
        },
        pet_breed: {
          required
        },
        pet_eye_color: {
          required
        },
        pet_details: {
          required
        },
        pet_type: {
          required
        },
        pet_gender: {
          required
        },
        has_pedigree: {
          required
        },
        has_chip: {
          required
        }

      }
    };
  },
  computed: {
    ...mapGetters(["user", "userData", "loggedIn", "shoppingCar"])
  },
  mounted() {
    let self = this;
    this.$emit("processing", false);
    self.baseData = { ...self.data, ...self.formData };
    self.loadedData = true;
    self.baseData.pet_age = self.shoppingCar.quoteDetails.extra_data.baseData.pet_age
  },
  methods: {
    ...mapActions(["getGender", "getCivilStatus", "fillProductRequirements"]),
    scrollToFirstError() {
      setTimeout(() => {
        let duration = 300;
        let parentEl = document.querySelectorAll(".scrolling-touch")[0];
        let targetEl = document.querySelector(".t-form-error");
        if (targetEl) {
          scrollTo(duration, parentEl, targetEl);
        }
      }, 50);
    },
    onSubmit() {
      let auxData = {
        ...this.data,
        ...this.baseData
      };
      let payload = {
        id: this.itemId,
        data: auxData
      };

      let self = this;
      this.$emit("processing", true);
      self.$v.$touch();
      if (self.$v.$invalid) {
        self.showErrors = true;
        self.scrollToFirstError();
        self.$emit("processing", false);
      } else {
        self.showErrors = false;
        this.fillProductRequirements(payload).then(result => {
          self.$emit("processing", false);
          this.$emit("save", result);
        });
      }
    },
    addPet(){
      
    }

    
  }
};
</script>