<template>
  <v-select ref="focusForm" v-if="loaded" v-model="selectedValue" :options="optionList" @input="$emit('input', selectedValue); $emit('change', selectedValue);" @search="onSearch" index="id" return-object label="name" class="w-full text-black">
    <template slot="no-options">
      Escribe para buscar
    </template>
    <template slot="option" slot-scope="option">
      <div class="d-center">
        {{ option.name }}
        </div>
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        {{ option.name }}
      </div>
    </template>
  </v-select>
</template>

<script>
import axios from 'axios'
import _ from 'lodash';
export default {
  name: "QuestionOption",
  data: function() {
    return {
      selectedValue: null,
      responseOptions: [],
      selected: null,
      // Para la animación de escribir
      isWriting: false,
      loaded: false,
      timeout: null,
      optionList: []
    };
  },
  props: {
    api_resource: {
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    data: {},
    value: {},
    httpConnector: {}
  },
  computed: {},
  mounted() {
    let self = this;
    // console.log("self.datarev", self.data);
    // console.log("self.datarev", self.value);
    
    if (self.data) {
      self.optionList = self.data
    }
    this.$nextTick(() => {
      self.selectedValue = self.value ? self.value: null
      self.loaded = true;
    })
  },
  methods: {
    setOptions(data) {
      this.optionList = data
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this, this.api_resource, this.httpConnector);
      }
    },
    search: _.debounce((loading, search, vm, api_resource, httpConnector) => {
      let url = api_resource + "?value=" + String(search)
      let http = httpConnector ? httpConnector: axios;
      http.get(url).then(res => {
        vm.setOptions(res.data)
        loading(false);
      });
    }, 350)
  }
};
</script>

<style >
</style>
