var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"w-full flex flex-wrap justify-center",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-2xl sm:text-3xl md:text-4xl font-extrabold flex"},[_c('h2',{staticClass:"t-title-2"},[_vm._v("\n          "+_vm._s(_vm.item.product.name)+"\n        ")])]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Datos de cuenta")]),_vm._v(" "),_c('div',{staticClass:"t-section-content py-5"},[_c('div',{staticClass:"t-input-group"},[_c('label',{staticClass:"t-label",attrs:{"for":"email"}},[_vm._v("Email")]),_vm._v(" "),_c('div',{staticClass:"flex-1 cursor-not-allowed"},[_c('div',{staticClass:"w-100 text-left t-form"},[_vm._v("\n                  "+_vm._s(_vm.baseData.email)+"\n                ")])])])])])]),_vm._v(" "),(_vm.loadedData)?_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Contacto de Emergencia")]),_vm._v(" "),_c('div',{staticClass:"t-section-content py-5"},[_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.name && !_vm.$v.baseData.name.$invalid) ||
                      !_vm.$v.baseData.name
                  ),"text":'Nombre',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.name.$model),expression:"$v.baseData.name.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.name.$invalid && _vm.showErrors ? 'invalid' : ''
                  ],attrs:{"type":"text","placeholder":"Nombre"},domProps:{"value":(_vm.$v.baseData.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Nombre requerido\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.first_name &&
                      !_vm.$v.baseData.first_name.$invalid) ||
                      !_vm.$v.baseData.first_name
                  ),"text":'Apellido paterno',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.first_name.$model),expression:"$v.baseData.first_name.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.first_name.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","placeholder":"Apellido paterno"},domProps:{"value":(_vm.$v.baseData.first_name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.first_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.first_name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Apellido requerido\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.last_name &&
                      !_vm.$v.baseData.last_name.$invalid) ||
                      !_vm.$v.baseData.last_name
                  ),"text":'A. Materno',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.last_name.$model),expression:"$v.baseData.last_name.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.last_name.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"type":"text","placeholder":"Apellido materno"},domProps:{"value":(_vm.$v.baseData.last_name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.last_name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.last_name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Apellido requerido\n                ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.phone_number &&
                      !_vm.$v.baseData.phone_number.$invalid) ||
                      !_vm.$v.baseData.phone_number
                  ),"text":'Teléfono',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.baseData.phone_number.$model),expression:"$v.baseData.phone_number.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                    _vm.$v.baseData.phone_number.$invalid && _vm.showErrors
                      ? 'invalid'
                      : ''
                  ],attrs:{"placeholder":"Escribe 10 números","type":"number","minlength":"10","maxlength":"10","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"},domProps:{"value":(_vm.$v.baseData.phone_number.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.baseData.phone_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!_vm.$v.baseData.phone_number.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Número teléfonico requerido\n                ")]):_vm._e(),_vm._v(" "),(!_vm.$v.baseData.phone_number.numeric && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Digita únicamente números.\n                ")]):_vm._e(),_vm._v(" "),(!_vm.$v.baseData.phone_number.minLengthValue && _vm.showErrors || !_vm.$v.baseData.phone_number.maxLengthValue && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Sólo digita 10 números sin espacios.\n                ")]):_vm._e()])],1)])])]):_vm._e(),_vm._v(" "),(_vm.loadedData)?_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Datos del viaje")]),_vm._v(" "),_c('div',{staticClass:"t-section-content py-5"},[_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.country_origin_of_travel &&
                      !_vm.$v.baseData.country_origin_of_travel.$invalid) ||
                      !_vm.$v.baseData.country_origin_of_travel
                  ),"text":'País de salida',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('c-autocomplete',{staticClass:"t-form",attrs:{"api_resource":"catalog/continental-origins","data":[_vm.$v.baseData.country_origin_of_travel.$model],"httpConnector":_vm.$http},model:{value:(_vm.$v.baseData.country_origin_of_travel.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.country_origin_of_travel, "$model", $$v)},expression:"$v.baseData.country_origin_of_travel.$model"}}),_vm._v(" "),(
                    !_vm.$v.baseData.country_origin_of_travel.id.required &&
                      _vm.showErrors
                  )?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  País de salida del viaje requerido\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.type_of_travel &&
                      !_vm.$v.baseData.type_of_travel.$invalid) ||
                      !_vm.$v.baseData.type_of_travel
                  ),"text":'Tipo de viaje',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('c-autocomplete',{staticClass:"t-form",attrs:{"api_resource":"catalog/continental-destinies","data":[_vm.$v.baseData.type_of_travel.$model],"httpConnector":_vm.$http},model:{value:(_vm.$v.baseData.type_of_travel.$model),callback:function ($$v) {_vm.$set(_vm.$v.baseData.type_of_travel, "$model", $$v)},expression:"$v.baseData.type_of_travel.$model"}}),_vm._v(" "),(!_vm.$v.baseData.type_of_travel.id.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Tipo de viaje requerido\n                ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                    (_vm.$v.baseData.dates_travel &&
                      !_vm.$v.baseData.dates_travel.$invalid) ||
                      !_vm.$v.baseData.dates_travel
                  ),"text":'Fechas del viaje: salida y vuelta',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('t-datepicker',{staticClass:"border-2 border-primary rounded-md text-primary text-xl bg-purple-50 w-96",attrs:{"minDate":new Date(),"required":true,"range":true,"date-formatter":_vm.dateFormatter,"date-parser":_vm.dateParser,"inline":true,"readonly":true,"date-format":"YYYY-MM-DD","user-format":"DD/MM/YYYY"},model:{value:(_vm.baseData.dates_travel),callback:function ($$v) {_vm.$set(_vm.baseData, "dates_travel", $$v)},expression:"baseData.dates_travel"}}),_vm._v(" "),(!_vm.$v.baseData.name.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                  Fechas del viaje necesarias\n                ")]):_vm._e()],1)],1)])])]):_vm._e(),_vm._v(" "),(_vm.loadedData)?_c('div',{staticClass:"w-full px-5"},[_c('div',{staticClass:"t-form-section"},[_c('div',{staticClass:"t-section-title py-0"},[_vm._v("Datos de los viajeros")]),_vm._v(" "),(_vm.travelers_info.length)?_c('div',{staticClass:"t-section-content py-5"},_vm._l((_vm.$v.travelers_info.$each.$iter),function(traveler,index){return _c('div',{key:index,staticClass:"rounded-xl my-2 p-5 border-2 border-primary"},[_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.nombre && !traveler.nombre.$invalid) ||
                        !traveler.nombre
                    ),"text":'Nombre',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(traveler.nombre.$model),expression:"traveler.nombre.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                      traveler.nombre.$invalid && _vm.showErrors ? 'invalid' : ''
                    ],attrs:{"type":"text","placeholder":"Nombre"},domProps:{"value":(traveler.nombre.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(traveler.nombre, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!traveler.nombre.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Nombre requerido\n                  ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.apellido && !traveler.apellido.$invalid) ||
                        !traveler.apellido
                    ),"text":'Primer apellido',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(traveler.apellido.$model),expression:"traveler.apellido.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                      traveler.apellido.$invalid && _vm.showErrors
                        ? 'invalid'
                        : ''
                    ],attrs:{"type":"text","placeholder":"Apellido paterno"},domProps:{"value":(traveler.apellido.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(traveler.apellido, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(!traveler.apellido.required && _vm.showErrors)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Nombre requerido\n                  ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.fechaNac && !traveler.fechaNac.$invalid) ||
                        !traveler.fechaNac
                    ),"text":'Fecha de nacimiento',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('t-datepicker',{staticClass:"border border-primary text-primary text-xl bg-purple-50 w-full",class:[
                      traveler.fechaNac.$invalid && _vm.showErrors
                        ? 'invalid'
                        : ''
                    ],attrs:{"required":true,"date-formatter":_vm.dateFormatter,"date-parser":_vm.dateParser,"date-format":"YYYY-MM-DD","user-format":"DD/MM/YYYY"},model:{value:(traveler.fechaNac.$model),callback:function ($$v) {_vm.$set(traveler.fechaNac, "$model", $$v)},expression:"traveler.fechaNac.$model"}}),_vm._v(" "),(_vm.showErrors && !traveler.fechaNac.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Campo requerido.\n                  ")]):_vm._e(),_vm._v(" "),(
                      _vm.showErrors &&
                        _vm.baseData.travelers_info[index].fechaNac_validation.invalid
                    )?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    La fecha de nacimiento de no corresponde con la edad\n                    especificada.\n                  ")]):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.edad && !traveler.edad.$invalid) ||
                        !traveler.edad
                    ),"text":'Edad',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(traveler.edad.$model),expression:"traveler.edad.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                      traveler.edad.$invalid && _vm.showErrors ? 'invalid' : ''
                    ],attrs:{"type":"text"},domProps:{"value":(traveler.edad.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(traveler.edad, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !traveler.edad.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Campo requerido.\n                  ")]):_vm._e(),_vm._v(" "),(_vm.showErrors && !traveler.fechaNac.integer)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Sólo se permiten números.\n                  ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.pasaporte && !traveler.pasaporte.$invalid) ||
                        !traveler.pasaporte
                    ),"text":'Pasaporte',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(traveler.pasaporte.$model),expression:"traveler.pasaporte.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                      traveler.pasaporte.$invalid && _vm.showErrors
                        ? 'invalid'
                        : ''
                    ],attrs:{"type":"text"},domProps:{"value":(traveler.pasaporte.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(traveler.pasaporte, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !traveler.pasaporte.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Campo requerido.\n                  ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.email && !traveler.email.$invalid) ||
                        !traveler.email
                    ),"text":'Email',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(traveler.email.$model),expression:"traveler.email.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                      traveler.email.$invalid && _vm.showErrors ? 'invalid' : ''
                    ],attrs:{"type":"text"},domProps:{"value":(traveler.email.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(traveler.email, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !traveler.email.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Campo requerido.\n                  ")]):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"t-input-group"},[_c('t-label',{staticClass:"t-label",attrs:{"showStatus":_vm.showErrors,"valid":Boolean(
                      (traveler.telefono && !traveler.telefono.$invalid) ||
                        !traveler.telefono
                    ),"text":'Teléfono',"required":true}}),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-full"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(traveler.telefono.$model),expression:"traveler.telefono.$model",modifiers:{"trim":true}}],staticClass:"t-form",class:[
                      traveler.telefono.$invalid && _vm.showErrors
                        ? 'invalid'
                        : ''
                    ],attrs:{"type":"text"},domProps:{"value":(traveler.telefono.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(traveler.telefono, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),(_vm.showErrors && !traveler.telefono.required)?_c('div',{staticClass:"t-form-error"},[_vm._v("\n                    Campo requerido.\n                  ")]):_vm._e()])],1)])}),0):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.showAPIErrors)?_c('div',{staticClass:"w-full flex flex-wrap justify-end"},[_c('div',{staticClass:"t-form-error block"},_vm._l((_vm.ApiErrorMessages),function(error,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(error.title))])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-wrap justify-end mt-5 mb-10"},[(_vm.showErrors && _vm.$v.baseData.$invalid)?_c('div',{staticClass:"\n            w-full\n            rounded-md\n            px-5\n            py-2\n            border-2 border-red-700\n            bg-red-50\n            text-red-700\n            my-3\n          "},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":['fas', 'exclamation-triangle']}}),_vm._v("\n          Algunos campos requieren de tu atención antes de poder continuar.\n        ")],1):_vm._e(),_vm._v(" "),_c('button',{staticClass:"\n            bg-special-3\n            h-10\n            px-10\n            rounded-md\n            text-primary text-2xl\n            w-full\n            sm:w-auto\n          ",attrs:{"type":"submit"}},[_vm._v("\n          Confirmar\n        ")])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-secondary mb-5 rounded-md px-5 y-2"},[_c('p',{staticClass:"t-alert success"},[_vm._v("\n          Usaremos esta información para generar el seguro, es necesario que\n          revises los requisitos y que confirmes que la información es\n          correcta.\n        ")]),_vm._v(" "),_c('p',{staticClass:"t-alert success"},[_vm._v("\n          Después de confirmar, no se podrá realizar ningún cambio.\n        ")])])}]

export { render, staticRenderFns }