/* eslint-disable prettier/prettier */ /* eslint-disable prettier/prettier */
<template>
  <div
    class="result-wraper w-full flex flex-wrap justify-center overflow-hidden h-full"
  >
    
    <loader v-if="showLoader" />
        
    <t-modal
      header="Datos de emisión"
      v-model="showModal"
      :clickToClose="false"
      :escToClose="false"
      variant="form"
    >
      <loader v-if="showProcessingRequirementsLoader" />
      <primero-seguros-requirements-form
        v-if="
          currentItemRequirements &&
            [2].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></primero-seguros-requirements-form>

      <dentegra-requirements-form
        v-if="currentItemRequirements && [5].includes(currentItemRequirements.category.id)"
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></dentegra-requirements-form>

      <chubb-requirements-form
        v-if="
          currentItemRequirements &&
            [8].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></chubb-requirements-form>

      <default-requirements-form
        v-if="
          currentItemRequirements &&
            [3, 7, 10].includes(currentItemRequirements.category.id)
        "
        :data="userDataDict"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></default-requirements-form>

      <mascotas-requirements-form
        v-if="
          currentItemRequirements &&
            [9].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></mascotas-requirements-form>

      <covid-requirements-form
        v-if="
          currentItemRequirements &&
            [4].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></covid-requirements-form>

      <surgical-requirements-form
        v-if="
          currentItemRequirements &&
            [11].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></surgical-requirements-form>

      <funeral-requirements-form
        v-if="
          currentItemRequirements &&
            [6].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></funeral-requirements-form>

      <personal-accidents-requirements-form
        v-if="
          currentItemRequirements &&
            [1].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></personal-accidents-requirements-form>

      <vrim-requirements-form
        v-if="
          currentItemRequirements &&
            [13].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></vrim-requirements-form>

      <continental-requirements-form
        v-if="
          currentItemRequirements &&
            [12].includes(currentItemRequirements.category.id)
        "
        :data="baseRequeriments"
        :lastQuoteData="lastQuoteData"
        :itemId="currentItemRequirements.id"
        :item="currentItemRequirements"
        @processing="showProcessingRequirementsLoader = $event"
        @save="confirmedRequirements()"
        @close="showModal = false"
      ></continental-requirements-form>
    </t-modal>








    <t-modal
      header="Pago de póliza"
      v-model="showPaymentModal"
      :clickToClose="false"
      :escToClose="false"
      variant="form"
    >
      <loader v-if="showPaymentLoader"/>
      
      
      <stripe-payment-component
        v-if="
          stripePaymentObject.stripe_account &&
          stripePaymentObject.client_secret
        "
        :stripeAccount="stripePaymentObject.stripe_account"
        :clientSecret="stripePaymentObject.client_secret"
        :userData="stripePaymentObject.user"
        :productData="stripePaymentObject.product"
        @payed="showPaymentModal = false"
      >
      </stripe-payment-component>

      <soap-payment-component v-if="primeroSegurosPaymentObject.product"
        :item_payment="item_payment"
        :userData="primeroSegurosPaymentObject.user"
        :productData="primeroSegurosPaymentObject.product"
        @payed="showPaymentModal = false"
      >
      </soap-payment-component>

    </t-modal>

    <div
      class="w-full max-h-9/10-screen overflow-hidden sm:w-9/10 lg:w-8/10 xl:w-8/10 flex flex-wrap items-start"
    >
      <div
        v-if="shopingListLoaded"
        class="w-full flex flex-wrap title text-primary text-3xl font-bold my-5 sm:my-16 z-0 px-5"
      >
        <span
          class="text-2xl sm:text-4xl font-extrabold uppercase"
          data-aos="fade-left"
          data-aos-delay="300"
          data-aos-anchor-placement="top-bottom"
          >Productos cotizados</span
        >
        <span
          class="text-xl sm:text-2xl"
          data-aos="fade-left"
          data-aos-delay="600"
          data-aos-anchor-placement="top-bottom"
        >
          ({{ shopingCount }}
          {{ shopingCount == 1 ? "elemento" : "elementos" }})
        </span>
      </div>
      <div class="w-full h-vh-5/10 px-5 overflow-hidden" style="height:500px;">
        <template v-if="!shopingCount">
          <div
            class="text-white text-2xl bg-primary-light border-2 border-primary-dark p-5 rounded-lg text-center"
          >
            Aún no has agregado nada al carrito, podrás seleccionar algunos
            productos después de realizar una cotización
          </div>
          <div class="w-full flex justify-center py-3">
            <router-link
              to="/our-services"
              class="bg-special-2 text-2xl p-3 rounded-md border-secondary-light border-2 text-white"
            >
              Ver productos
            </router-link>
          </div>
        </template>
        <template v-else>
          <p class="t-alert success mt-0 mb-5">
            Antes de poder realizar el pago, es necesario que revises los
            requisitos del respectivo seguro y que confirmes que la información
            es correcta.
          </p>
        </template>
        <div class="overflow-x-scroll">


          <table v-if="shopingListLoaded && !isMobileView" class="w-full table-fixed" style="">

            <tr class="border-b-2 border-t-2">
              
              <th colspan="2" class="w-44">Tipo de seguro</th>
              
              <th class="w-32">
              <div class="flex justify-center w-full">
                  <div>Prima</div>
                  <div
                    class="mx-3 text-tertiary h-5 w-5 h-min-5 w-min-5 border-primary border rounded-full"
                    title="Es el monto que deberás Pagar de forma anual para obtener el seguro"
                  >
                    ?
                  </div>
                </div>
              </th>
              
              <th class="w-40">
                Requisitos
              </th>

              <th class="w-40">
                Realizar pago
              </th>

              <th class="w-44">
                Comentarios
              </th>

            </tr>


            <tr v-for="item in shopingList" :key="item.id + '-result'">

              <td>
                <img
                  :src="item.category.image_xl_primary"
                  class="w-14 h-14 sm:w-20 sm:h-20"
                />
              </td>

              <td>
                <div
                  class="w-full text-center sm:w-3/10 text-center text-primary-light leading-4"
                >
                  {{ item.category.title_1.join(" ") }}
                  <span class="text-primary uppercase">{{
                    item.product.name
                  }}</span>
                </div>
              </td>

              <td class="text-center">
                <div class="font-bold">
                  
                  $
                  
                  <span v-if="item.category.id == 7">
                    {{ getPackageSelected(item.category.id).policy_premium }}
                  </span>
                  <span v-else>
                    {{
                      item.extra_data && item.extra_data.insured_amount
                        ? numberWithCommas(Number(item.extra_data.insured_amount))
                        : numberWithCommas(Number(item.product.policy_premium))
                    }}
                  </span>                  

                  <h1 v-if="item.category.id == 5" style="font-weight: normal;">(Incluye IVA)</h1>

                </div>
              </td>

              <td class="text-center">
                <button
                  v-if="item.step.id == 'SCAR'"
                  class="bg-special-2 rounded-sm px-10 py-1 text-primary"
                  @click="openRequerimetsModal(item)"
                >
                  Requisitos
                </button>
                <div v-if="['AFPY', 'PAYD', 'PYST'].includes(item.step.id)">
                  <font-awesome-icon
                    :icon="['fas', 'check-circle']"
                    class="fa-lg text-secondary"
                  />
                </div>
              </td>

              <td class="text-center">
                <button
                  v-if="['SCAR', 'AFPY'].includes(item.step.id)"
                  class="bg-special-3 rounded-sm px-10 py-1 text-primary"
                  :disabled="item.step.id == 'SCAR'"
                  @click="makePayment(item)"
                > Pagar
                  <!-- <div style="display:flex; justify-content:center; align-items:center;">
                    <h3 style="margin-right:10px;">Pagar</h3>  
                    <img v-if="item.category.id == 2"
                      src="@/assets/img/stripe/visa_mastercard.jpeg"
                      class="w-14 h-5"
                    /> 
                  </div>   -->
                </button>

                <button @click="sendPaymentUrl(item)" class="btn_url_payment"></button>

                <div v-if="['PAYD', 'PYST'].includes(item.step.id)">
                  <font-awesome-icon
                    :icon="['fas', 'check-circle']"
                    class="fa-lg text-secondary"
                  />
                </div>
              </td>

              <td>
                <div
                  v-if="['PAYD'].includes(item.step.id)"
                  class="text-primary"
                >
                  {{ item.category.thank_text_purchase }}
                </div>
                <div
                  v-if="['PYST'].includes(item.step.id)"
                  class="text-primary"
                >
                  Gracias por seleccionar este paquete.<br />Apenas confirmemos
                  tu pago, comenzaremos con la emisión de tu seguro. Te
                  contactarémos por email para los siguientes pasos.
                  {{ item.extra_data.final_response.message }}
                </div>
              </td>
            </tr>

          </table>

          <!-- <div class="t-alert success mt-0 mb-5 main_card" style="border-color: #7c5ece; background-color:white; height: 400px;"> -->
            
            <table v-if="shopingListLoaded && isMobileView" style="width:100%; margin-top:10px;"> 
              <!-- <div class="card_background_package" style="position:absolute; left:0; right: 0; margin:auto; width: 86%; height: 20%;"></div> -->
              
              <div class="card_background_package" style="border-style:hidden; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <th colspan="2" class="w-44" style="color:black;">Tipo de seguro</th>

                  <tr v-for="item in shopingList" :key="item.id + '-result'" style="border-style:hidden; display: flex; justify-content: center; align-items: center;">
                    <td>
                      <img
                        :src="item.category.image_xl_primary"
                        class="w-14 h-14 sm:w-20 sm:h-20"
                      />
                    </td>

                    <td>
                      <div
                        class="w-full text-center sm:w-3/10 text-center text-primary-light leading-4"
                      >
                        {{ item.category.title_1.join(" ") }}
                        <span class="text-primary uppercase">{{
                          item.product.name
                        }}</span>
                      </div>
                    </td>
                  </tr>
              </div>

              

                <tr class="border-b-2 border-t-2 bg-light-gray">
                  <th class="w-32">
                  <div class="flex justify-center w-full" style="margin-top: 15px;">
                      <div style="color:black;">Prima</div>
                      <div
                        class="mx-3 text-tertiary h-5 w-5 h-min-5 w-min-5 border-primary border rounded-full"
                        title="Es el monto que deberás Pagar de forma anual para obtener el seguro"
                      >
                        ?
                      </div>
                    </div>
                  </th>
                </tr>
                
                
                <tr v-for="item in shopingList" :key="item.id + '-result'" style="text-align: center; color:black; border-top:hidden !important;" class="bg-light-gray">
                    <div class="font-bold">
                      $
                      {{
                        item.extra_data && item.extra_data.insured_amount
                          ? numberWithCommas(Number(item.extra_data.insured_amount))
                          : numberWithCommas(Number(item.product.policy_premium))
                      }}

                      <h1 v-if="item.category.id == 5" style="font-weight: normal;">(Incluye IVA)</h1>
                    </div>
                </tr>
                


                <tr v-for="item in shopingList" :key="item.id + '-result'" style="border-style : hidden;" class="bg-light-gray">
                  <td class="text-center" style="">
                    <button
                      v-if="item.step.id == 'SCAR'"
                      style="width:95% !important; margin-top: 15px;"
                      class="bg-special-2 rounded-sm px-10 py-1 text-primary"
                      @click="openRequerimetsModal(item)"
                    >
                      Requisitos
                    </button>
                    <div v-if="['AFPY', 'PAYD', 'PYST'].includes(item.step.id)">
                      <font-awesome-icon
                        :icon="['fas', 'check-circle']"
                        class="fa-lg text-secondary"
                      />
                    </div>
                  </td>
                </tr>
                
                <tr v-for="item in shopingList" :key="item.id + '-result'" style="border-style:hidden; text-align: center;" class="bg-light-gray">
                    <button
                      v-if="['SCAR', 'AFPY'].includes(item.step.id)"
                      class="bg-special-3 rounded-sm px-10 py-1 text-primary"
                      :disabled="item.step.id == 'SCAR'"
                      @click="makePayment(item)"
                      style="width:95% !important; margin-top: 15px; margin-bottom: 15px; text-align: center;"
                    >Pagar

                      <!-- <div style="display:flex; justify-content:center; align-items:center;">
                        <h3 style="margin-right:10px;">Pagar</h3>
                        <img v-if="item.category.id == 2"
                          src="@/assets/img/stripe/visa_mastercard.jpeg"
                          class="w-14 h-5"
                        />
                      </div>   -->
                      
                    </button>

                    <button @click="sendPaymentUrl(item)" class="btn_url_payment"></button>

                    <div v-if="['PAYD', 'PYST'].includes(item.step.id)">
                      <font-awesome-icon
                        :icon="['fas', 'check-circle']"
                        class="fa-lg text-secondary"
                      />
                    </div>
                </tr>
                
                
                <tr class="border-b-2 border-t-2 bg-light-gray"   style="width:100% !important;">
                  <th colspan="2" class="w-44" >Comentarios</th>
                </tr>
                
                <tr v-for="item in shopingList" :key="item.id + '-result'" class="bg-light-gray" style="border-style:hidden;">

                  <div
                    v-if="['PAYD'].includes(item.step.id)"
                    class="text-primary"
                  >
                    {{ item.category.thank_text_purchase }}
                  </div>
                  <div
                    v-if="['PYST'].includes(item.step.id)"
                    class="text-primary"
                  >
                    Gracias por seleccionar este paquete.<br />Apenas confirmemos
                    tu pago, comenzaremos con la emisión de tu seguro. Te
                    contactarémos por email para los siguientes pasos.
                    {{ item.extra_data.final_response.message }}
                  </div>
                </tr>

            </table>
          <!-- </div> -->



        </div>
        <div v-if="allIsPayed" class="w-full flex justify-center my-3">
          <router-link to="/">
            <button
              class="bg-special-2 py-1 px-10 rounded-md text-primary mt-5 text-2xl transition-all transform hover:scale-105"
              @click="shoppingCar.reset()"
            >
              Seguir Comprando
            </button>
          </router-link>
        </div>


        <!-- Despliega errores provenientes de las APIs -->
        <div v-if="showAPIErrors" class="w-full flex flex-wrap justify-end">
          <div class="t-form-error block">
            <ul v-for="(error, index) in ApiErrorMessages" :key="index">
              <li>{{ error.title }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loader from "../components/Loader";
import ShoppingCar from "../store/Modules/ShoppingCar";
import ChubbRequirementsForm from "./productRequerimentsForms/Chubb";
import DefaultRequirementsForm from "./productRequerimentsForms/Default";
import MascotasRequirementsForm from "./productRequerimentsForms/Mascotas";
import PrimeroSegurosRequirementsForm from "./productRequerimentsForms/PrimeroSeguros";
import DentegraRequirementsForm from "./productRequerimentsForms/Dentegra";
import CovidRequirementsForm from "./productRequerimentsForms/Covid";
import SurgicalRequirementsForm from "./productRequerimentsForms/Surgical";
import FuneralRequirementsForm from "./productRequerimentsForms/Funeral";
import PersonalAccidentsRequirementsForm from "./productRequerimentsForms/PersonalAccidents";
import VrimRequirementsForm from "./productRequerimentsForms/VRIM";
import ContinentalRequirementsForm from "./productRequerimentsForms/Continental.vue";
import StripePaymentComponent from "../components/StripePaymentCard.vue";
import SoapPaymentComponent from "../components/SoapPaymentCard.vue";




import { APIServices } from "../constants/config";

export default {
  name: "ShoppingCarPage",
  data:() => ({
      showErrors : false,
      showAPIErrors: false,
      showLoader: false,
      showPaymentModal: false,
      showPaymentLoader: false,
      showModal: false,
      showProcessingRequirementsLoader: false,
      shopingListLoaded: false,
      currentItemRequirements: null,
      trackingPaymentIds: [],
      trackingPaymentInstance: null,

      hiringRequerimentsData: {
        id: 1,
        name: "minombre",
        value: 10
      },

      baseRequeriments: {
        age: "",
        beginning_of_validity: "",
        bicycle_caracteristics: "",
        bicycle_serial_number: "",
        bicycle_use: "",
        bicycle_value: "",
        bicycle_year_model: "",
        born_date: "",
        born_day: "",
        born_month: "",
        born_year: "",
        business_characteristics: "",
        company_subgenre: "",
        create_date: "",
        dates_travel: [],
        departure_date_of_travel: "",
        return_date_of_travel: "",
        email: "",
        end_of_validity: "",
        first_name: "",
        full_name: "",
        gender: "",
        is_own_property: "",
        last_name: "",
        name: "",
        number_of_basements: "",
        number_of_levels: "",
        occupation: "",
        pet_age: "",
        pet_name: "",
        property_age: "This is the property age",
        property_content_value: "",
        property_value: "",
        pyme_antiquity: "",
        street: "",
        type_of_room: "",
        type_of_travel: "",
        destiny_of_travel: "",
        country_origin_of_travel: "",
        travelers_ages: [],
        zip_code: "",
        zip_code_instance: "",
        rfc: "",
        homoclave: "",
        civil_status: "",
        country: {
          id: null,
          name: ""
        },
        state: {
          id: null,
          name: ""
        },
        town_council: {
          id: null,
          name: ""
        },
        colony: {
          id: null,
          name: ""
        }
      },
      stripePaymentObject: {
        stripeAccount: "",
        clientSecret: "",
        userData: {}
      },
      primeroSegurosPaymentObject: {
        userData: {}
      },   
      item_payment:""
  }),
  components: {
    loader: Loader,
    "chubb-requirements-form": ChubbRequirementsForm,
    "default-requirements-form": DefaultRequirementsForm,
    "mascotas-requirements-form": MascotasRequirementsForm,
    "primero-seguros-requirements-form": PrimeroSegurosRequirementsForm,
    "dentegra-requirements-form": DentegraRequirementsForm,
    "covid-requirements-form": CovidRequirementsForm,
    "surgical-requirements-form": SurgicalRequirementsForm,
    "funeral-requirements-form": FuneralRequirementsForm,
    "personal-accidents-requirements-form": PersonalAccidentsRequirementsForm,
    "vrim-requirements-form": VrimRequirementsForm,
    "continental-requirements-form": ContinentalRequirementsForm,
    "stripe-payment-component": StripePaymentComponent,
    "soap-payment-component": SoapPaymentComponent,
  },
  domain: window.location.origin,

  computed: {
    ...mapGetters(["shoppingCar", "lastQuoteData", "userData", "loggedIn"]),
    userDataDict() {
      let data = {
        ...this.userData,
        ...this.userData.user_address[0]
      };
      return data;
    },
    shopingCount() {
      return this.shoppingCar.getItems().length;
    },
    shopingList() {
      let shoppinglist = [];
      if (
        this.shoppingCar &&
        this.shoppingCar.quoteDetails &&
        this.shoppingCar.quoteDetails.listed_categories
      ) {
        shoppinglist = this.shoppingCar.quoteDetails.listed_categories.filter(
          item => item.product
        );
      }
      return shoppinglist;
    },
    allIsPayed() {
      // console.log(this.shopingList);
      let allPayed = true;
      this.shopingList.forEach(item => {
        if (!["PAYD", "PYST"].includes(item.step.id)) {
          allPayed = false;
        }
      });
      return allPayed;
    },
    isMobileView() {
      return this.$store.state.GeneralConfigStore.isMobileView;
    }
  },
  mounted() {
    this.$store.state.ShoppingCarStore.shoppingCar = new ShoppingCar([]);
    this.setInitialState();
    this.executeInsuranceWithoutRequirements()
  },
  methods: {
    ...mapActions(["generatePaymentIntent"]),
    async setInitialState() {
      await this.shoppingCar.getQuoteWithDetails()
        .then(() => {
          //console.log("-------------------------");
          //console.log(result);
          this.shopingListLoaded = true;
        })
        .catch(err => {
          console.log(err);
          throw err;
        });
      this.mergeDisponibleData();
    },
    executeInsuranceWithoutRequirements(){

      console.log("Init")
      var self = this
      setTimeout(() => {        
        var result = self.shopingList.filter((item) => item.category.id == 7)
        if(result.length){
          var insurance_without_requirements = result[0]
          self.openRequerimetsModal(insurance_without_requirements)
        }
        
      }, 2000);
    },
    async mergeDisponibleData() {
      let self = this;
      // console.log("Dentro de mergeDisponibleData");
      self.baseRequeriments["full_name"] = await self.getRequeriment(1).join(" ");
      self.baseRequeriments["name"] = await self.getRequeriment(1)[0];
      self.baseRequeriments["first_name"] = await self.getRequeriment(1)[1];
      self.baseRequeriments["last_name"] = await self.getRequeriment(1)[2];
      self.baseRequeriments["born_date"] = await self.getRequeriment(2);
      self.baseRequeriments["born_day"] = "";
      self.baseRequeriments["born_month"] = "";
      self.baseRequeriments["born_year"] = "";
      self.baseRequeriments["age"] = "";
      self.baseRequeriments["occupation"] = await self.getRequeriment(3);
      self.baseRequeriments["type_of_room"] = await self.getRequeriment(4);
      self.baseRequeriments["number_of_basements"] = await self.getRequeriment(5);
      self.baseRequeriments["number_of_levels"] = await self.getRequeriment(6);
      self.baseRequeriments["zip_code"] = {name: self.getRequeriment(7).zip_code,...self.getRequeriment(7)};

      try {
        self.baseRequeriments["street"] = await self.getRequeriment(8)[0];
        self.baseRequeriments["num_ext"] = await self.getRequeriment(8)[1];
        self.baseRequeriments["num_int"] = await self.getRequeriment(8)[2];
      } catch {
        self.baseRequeriments["street"] = "";
        self.baseRequeriments["num_ext"] = "";
        self.baseRequeriments["num_int"] = "";
      }

      self.baseRequeriments["business_characteristics"] = await self.getRequeriment(9);
      self.baseRequeriments["pyme_antiquity"] = await self.getRequeriment(10);
      self.baseRequeriments["company_subgenre"] = await self.getRequeriment(11);
      self.baseRequeriments["dates_travel"] = await self.getRequeriment(12);
      self.baseRequeriments["departure_date_of_travel"] = await self.getRequeriment(12)[0];
      self.baseRequeriments["return_date_of_travel"] = await self.getRequeriment(12)[1];

      try {
        self.baseRequeriments["type_of_travel"] = await self.getRequeriment(14);
        self.baseRequeriments["destiny_of_travel"] = await self.getRequeriment(14);
      } catch (error) {
        console.log("Se encontro un error: ", error);
        throw 250
      }
      self.baseRequeriments["pet_name"] = await self.getRequeriment(15);
      self.baseRequeriments["pet_age"] = await self.getRequeriment(16);
      self.baseRequeriments["bicycle_value"] = await self.getRequeriment(17);
      self.baseRequeriments["bicycle_caracteristics"] = await self.getRequeriment(18);
      self.baseRequeriments["bicycle_year_model"] = await self.getRequeriment(19);
      self.baseRequeriments["bicycle_serial_number"] = await self.getRequeriment(20);
      self.baseRequeriments["bicycle_use"] = await self.getRequeriment(21);
      self.baseRequeriments["property_age"] = await self.getRequeriment(22);
      self.baseRequeriments["is_own_property"] = await self.getRequeriment(30);
      self.baseRequeriments["property_value"] = await self.getRequeriment(31);
      self.baseRequeriments["property_content_value"] = await self.getRequeriment(32);
      self.baseRequeriments["travelers_ages"] = await self.getRequeriment(33);
      self.baseRequeriments["country_origin_of_travel"] = await self.getRequeriment(34);      

      // self.baseRequeriments["variable_ejemplo"] = "Ejemplo";
      // var s = await self.getRequeriment(38)
      // alert(s)
      
      self.baseRequeriments["sex"] = await self.getRequeriment(38);
      self.baseRequeriments["zipcode_sura"] = await self.getRequeriment(39);
      
      self.baseRequeriments["gender"] = await self.getRequeriment("gender");
      self.baseRequeriments["email"] = await self.getRequeriment("email");
      self.baseRequeriments["rfc"] = await self.getRequeriment("rfc");
      self.baseRequeriments["homoclave"] = await self.getRequeriment("homoclave");
      self.baseRequeriments["civil_status"] = await self.getRequeriment("civil_status");
    },
    getRequeriment(id) {
      let self = this;
      let response = "";
      let auxValue = "";

      switch (id) {
        case 1:
          response = [
            self.userDataDict.name,
            self.userDataDict.first_name,
            self.userDataDict.last_name
          ];
          break;
        case 2:
          response = self.userDataDict.born_date;
          break;
        case 7:
          response = self.userDataDict.zip_code;
          break;
        case 8:
          response = [
            self.userDataDict.street,
            self.userDataDict.num_ext,
            self.userDataDict.num_int
          ];
          break;
        case 29:
          response = self.userDataDict.email;
          break;
        case 12:
          auxValue = self.lastQuoteData.requeriments.filter(
            item => item.id == id
          );
          if (auxValue.length) {
            response = auxValue[0].value;
          } else {
            response = ["", ""];
          }
          break;
        case 14:
          auxValue = self.lastQuoteData.requeriments.filter(
            item => item.id == id
          );
          if(auxValue.length > 0){
            response = this.$http
              .get(APIServices.continental_destinies, {
                params: {
                  id: auxValue[0].value
                }
              })
              .then(res => res.data)
              .then(res => {
                return res[0];
              });
          }
          break;
        case "email":
          response = self.userDataDict["email"]
            ? this.userDataDict["email"]
            : self.userDataDict.email;
          break;          
        case "rfc":
          response = self.userDataDict.rfc;
          break;
        case "homoclave":
          response = self.userDataDict.homoclave;
          break;
        case "gender":
          response = self.userDataDict.gender;
          break;
        case "civil_status":
          response = self.userDataDict.civil_status;
          break;
      }
      if (!response) {
        auxValue = self.lastQuoteData.requeriments.filter(
          item => item.id == id
        );
        if (auxValue.length) {
          response = auxValue[0].value;
        }
      }
      return response;
    },
    numberWithCommas(x) {
      return parseFloat(x)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    next() {},
    async makePayment(item) {

      this.showLoader = true;

      let self = this;
      let item_id = parseInt(item.category.id);

      if(item_id == 1 || item_id == 4 || item_id == 6 || item_id == 11){
        // console.log("Entre luego luego: " + item.category.id);
        let init_url = "";
        switch(item_id){
          case 1: //ACCIDENTES PERSONALES
            init_url = "/payments/confirm/1/";
            break;
          case 4:// COVID
            init_url = "/payments/confirm/4/";
            break;
          case 6://FUNERARIO
            init_url = "/payments/confirm/6/";
            break;
          default://QUIRURGICO
            init_url = "/payments/confirm/11/";
            break;
        }

        let ok_rul =
          window.location.origin + init_url +
          btoa(
            item.id + "-" + item.product.id + "-" + item.category.id + "-py"
          ) +
          "/";
        let nok_rul =
          window.location.origin + init_url +
          btoa(
            item.id + "-" + item.product.id + "-" + item.category.id + "-np"
          ) +
          "/";
        let process_url =
          window.location.origin + init_url +
          btoa(
            item.id + "-" + item.product.id + "-" + item.category.id + "-pn"
          ) +
          "/";

        //console.log("url_payment: " + item.extra_data.url_payment);
        let url = `${item.extra_data.url_payment}&ok=${ok_rul}&nok=${nok_rul}&process=${process_url}`;
        // console.log("item.extra_data.url_payment: " + item.extra_data.url_payment);
        // console.log("ok_rul: " + ok_rul);
        // console.log("nok_rul: " + nok_rul);
        // console.log("process_url: " + process_url)
        // console.log(item)

        
        /*
        console.log("ok_rul cifrado: ", ok_rul);
        console.log("nok_rul cifrado: ", nok_rul);
        console.log("process_url cifrado: ", process_url);
        console.log("url final: ", url);
        console.log("****************** FIN DATOS PARA PAGO ****************************");
        */
        window.open(url, "_blank");
        self.executeTrakingOfPayment(item.id);
      }

      //******** PRIMERO SEGUROS - BICICLETA *******
      else if (item.category.id == 2) {
        self.showPaymentModal = true;
        // console.log(item)
        // console.log("ITEM_JSON: " + JSON.stringify(item))
        self.shoppingCar.generatePaymentIntent(item).then(response => {
          self.primeroSegurosPaymentObject = response.data
          self.item_payment = item
          // console.log("Respuesta de payment intent: " , JSON.stringify(self.primeroSegurosPaymentObject))
        })
        //! Importante descomentar
        self.executeTrakingOfPayment(item.id);
      }

      //******** DENTEGRA - DENTAL *******
      else if (item.category.id == 5) {
        self.showPaymentModal = false;
        self.shoppingCar.generatePaymentIntent(item).then(response => {
          let url = response.data.url;
          window.open(url, "_blank");
        });
        self.executeTrakingOfPayment(item.id);
      }

      //******** GASTOS MÉDICOS *******
      else if (item.category.id == 7) {

        self.showPaymentModal = false;
        self.shoppingCar.generatePaymentIntent(item).then(response => {
          console.log(response)        
          let url = item["extra_data"]["payment"]["url"]["urlRedirect"]
          window.open(url, "_blank");
        });

        // console.log(item)
        // console.log(item["extra_data"])
        // console.log(item["extra_data"]["payment"])
        // console.log(item["extra_data"]["payment"]["url"]["urlRedirect"])

        // console.log(item["extra_data"]["payment"]["url"]["urlRedirect"])

        // self.executeTrakingOfPayment(item.id);
        // console.log("not implemented");
        //console.log(item);
        //window.open(url, "_blank");

        self.executeTrakingOfPayment(item.id);
      }

      //******** CHUBB - HOGAR *******
      if (item.category.id == 8) {
        let trc = item.extra_data.tc.t;
        let cnzd = item.extra_data.tc.c;

        //console.log("trc: ", trc);
        //console.log("cnzd: ", cnzd);

        /* Para la categoría de hogar, el pago es externo */
        // console.log(JSON.stringify(item.extra_data));
        let pharamsObj = {
          veI: item.extra_data.emition.ClaveId,
          za: item.extra_data.emition.PolizaId,
          cis: 1,
          bab: 1,
          kgb: 2,
          kli:
            window.location.origin +
            "/payments/confirm/8/" +
            btoa(item.id + "-" + item.product.id + "-" + item.category.id) +
            "/",
          trc: trc,
          cnzd: cnzd,
          aId: 1
        };

        //console.log("pharamsObj: ", pharamsObj);

        let queryParams = [];
        let url = "";

        for (const [key, val] of Object.entries(pharamsObj)) {
          //console.log(key, val);
          queryParams.push(key + "=" + val);
        }

        // let strChubbParams = queryParams.join("+");
        // console.log("Parametros a enviar: " + strChubbParams);
        //url = urlBase + "?alt=" + btoa(queryParams.join("+"));
        url = item.extra_data.url_payment + "?alt=" + btoa(queryParams.join("+"));
        //console.log("url: ", url);
        window.open(url, "_blank");
        self.executeTrakingOfPayment(item.id);
        
      }

      //******** MASCOTA *******
      else if(item.category.id == 9){
        /*Operación por Default. Se utiliza únicamente para envair un email tanto al agente de sabar
        como al usuario*/
        let url = APIServices.product_payment + item.id + "/";
        let payload = {};
        await self.$http
          .patch(url, payload)
          .then(() => {
            // console.log("Respuesta del server", result);
            // console.log("Mensaje: ", result.data.extra_data.final_response.message);
          })
          .catch(err => {
            // console.log(err)
            if (err.response) {
              self.showAPIErrors = true;
              self.ApiErrorMessages = err.response.data.messages.errors;
            }
          })
          .finally(() => {
            // console.log("Cerrando");
            self.shoppingCar.getQuoteWithDetails();
            window.close();
          });        
      }

      //******** CONTINENTAL - ACCIDENTES EN VIAJES *******
      else if (item.category.id == 12) {
        self.showPaymentModal = true;
        // console.log("ITEM: " + JSON.stringify(item));
        self.shoppingCar.generatePaymentIntent(item).then(response => {
          self.stripePaymentObject = response.data;
          // console.log(
          //   "Respuesta de payment intent: ",
          //   JSON.stringify(self.stripePaymentObject)
          // );
        });
        self.executeTrakingOfPayment(item.id);
      }
      //VRIM
      else if (item.category.id == 13) {
        self.showPaymentModal = true;
        self.shoppingCar.generatePaymentIntent(item).then(response => {
          self.stripePaymentObject = response.data;
          // console.log(
          //   "Respuesta de payment intent: ",
          //   self.stripePaymentObject
          // );
        });
        self.executeTrakingOfPayment(item.id);
      }

      
      //******** CUALQUIER OTRO SEGURO *******
      // else {
      //   console.log("entre aqui: " + item.category.id);
      //   Operación por Default. Se utiliza únicamente para envair un email tanto al agente de sabar
      //   como al usuario*//*
      //   let url = APIServices.product_payment + item.id + "/";
      //   let payload = {};
      //   await self.$http
      //     .patch(url, payload)
      //     .then(result => {
      //       console.log("Respuesta del server", result);
      //       console.log("Mensaje: ", result.data.extra_data.final_response.message);
      //     })
      //     .catch(err => {
      //       console.log(err);
      //     })
      //     .finally(() => {
      //       console.log("Cerrando");
      //       self.shoppingCar.getQuoteWithDetails();
      //       window.close();
      //     });
      // }

      this.showLoader = false;
    
    },



    async sendPaymentUrl(item) {

      // this.showLoader = true;

      let self = this;
      let item_id = parseInt(item.category.id);

      if(item_id == 1 || item_id == 4 || item_id == 6 || item_id == 11){
        // console.log("Entre luego luego: " + item.category.id);
        let init_url = "";
        switch(item_id){
          case 1: //ACCIDENTES PERSONALES
            init_url = "/payments/confirm/1/";
            break;
          case 4:// COVID
            init_url = "/payments/confirm/4/";
            break;
          case 6://FUNERARIO
            init_url = "/payments/confirm/6/";
            break;
          case 7://SURA
            init_url = "/payments/confirm/7/";
            break;
          default://QUIRURGICO
            init_url = "/payments/confirm/11/";
            break;
        }

        let ok_rul =
          window.location.origin + init_url +
          btoa(
            item.id + "-" + item.product.id + "-" + item.category.id + "-py"
          ) +
          "/";
        let nok_rul =
          window.location.origin + init_url +
          btoa(
            item.id + "-" + item.product.id + "-" + item.category.id + "-np"
          ) +
          "/";
        let process_url =
          window.location.origin + init_url +
          btoa(
            item.id + "-" + item.product.id + "-" + item.category.id + "-pn"
          ) +
          "/";

        //console.log("url_payment: " + item.extra_data.url_payment);
        let url = `${item.extra_data.url_payment}&ok=${ok_rul}&nok=${nok_rul}&process=${process_url}`;
        // console.log("item.extra_data.url_payment: " + item.extra_data.url_payment);
        // console.log("ok_rul: " + ok_rul);
        // console.log("nok_rul: " + nok_rul);
        // console.log("process_url: " + process_url)
        // console.log(item)

        
        /*
        console.log("ok_rul cifrado: ", ok_rul);
        console.log("nok_rul cifrado: ", nok_rul);
        console.log("process_url cifrado: ", process_url);
        console.log("url final: ", url);
        console.log("****************** FIN DATOS PARA PAGO ****************************");
        */





        self.shoppingCar.sendPaymentUrl(self.userData.email , url).then(response => {
          console.log(response)
          var { status } = response

          if(status == 200){
            this.$swal({ 
              icon:"success" , 
              title:"Buen Trabajo!" , 
              text:"La url de Pago ha sido enviada correctamente al Cliente!" , 
              timer:3000,
              showConfirmButton: false 
            });
          } else {
            this.$swal({ 
              icon:"error" , 
              title:"Oops!" , 
              text:"Ha ocurrido un error al momento de enviar la Url de Pago al Cliente!" , 
              timer:3000 , 
              showConfirmButton: false 
            });
          }

        });
        
        self.executeTrakingOfPayment(item.id);
      }



      //******** PRIMERO SEGUROS - BICICLETA *******
      // else if (item.category.id == 2) {
      //   self.showPaymentModal = true;
      //   self.shoppingCar.generatePaymentIntent(item).then(response => {
      //     self.primeroSegurosPaymentObject = response.data
      //     self.item_payment = item
      //   })
      //   self.executeTrakingOfPayment(item.id);
      // }


      //******** DENTEGRA - DENTAL *******
      else if (item.category.id == 5) {

        self.showPaymentModal = false;
        self.shoppingCar.generatePaymentIntent(item).then(response => {

          let url = response.data.url;
          self.shoppingCar.sendPaymentUrl(self.userData.email , url).then(response => {
            console.log(response)
            var { status } = response

            if(status == 200){
              this.$swal({ 
                icon:"success" , 
                title:"Buen Trabajo!" , 
                text:"La url de Pago ha sido enviada correctamente al Cliente!" , 
                timer:3000,
                showConfirmButton: false 
              });
            } else {
              this.$swal({ 
                icon:"error" , 
                title:"Oops!" , 
                text:"Ha ocurrido un error al momento de enviar la Url de Pago al Cliente!" , 
                timer:3000 , 
                showConfirmButton: false 
              });
            }

          });

        });
        self.executeTrakingOfPayment(item.id);
        
      }

      //******** GASTOS MÉDICOS *******
      else if (item.category.id == 7) {

        self.showPaymentModal = false;
        self.shoppingCar.generatePaymentIntent(item).then(response => {
          console.log(response)
          let url = item["extra_data"]["payment"]["url"]["urlRedirect"]

          self.shoppingCar.sendPaymentUrl(self.userData.email , url).then(response => {
            console.log(response)
            var { status } = response

            if(status == 200){
              this.$swal({ 
                icon:"success" , 
                title:"Buen Trabajo!" , 
                text:"La url de Pago ha sido enviada correctamente al Cliente!" , 
                timer:3000,
                showConfirmButton: false 
              });
            } else {
              this.$swal({ 
                icon:"error" , 
                title:"Oops!" , 
                text:"Ha ocurrido un error al momento de enviar la Url de Pago al Cliente!" , 
                timer:3000 , 
                showConfirmButton: false 
              });
            }

          });

        })
      }

      //******** CHUBB - HOGAR *******
      if (item.category.id == 8) {
        let trc = item.extra_data.tc.t;
        let cnzd = item.extra_data.tc.c;

        let pharamsObj = {
          veI: item.extra_data.emition.ClaveId,
          za: item.extra_data.emition.PolizaId,
          cis: 1,
          bab: 1,
          kgb: 2,
          kli:
            window.location.origin +
            "/payments/confirm/8/" +
            btoa(item.id + "-" + item.product.id + "-" + item.category.id) +
            "/",
          trc: trc,
          cnzd: cnzd,
          aId: 1
        };

        let queryParams = [];
        let url = "";

        for (const [key, val] of Object.entries(pharamsObj)) {
          //console.log(key, val);
          queryParams.push(key + "=" + val);
        }

        // let strChubbParams = queryParams.join("+");
        // console.log("Parametros a enviar: " + strChubbParams);
        //url = urlBase + "?alt=" + btoa(queryParams.join("+"));
        url = item.extra_data.url_payment + "?alt=" + btoa(queryParams.join("+"));

        console.log(url)
        // This is the part when we need to send the email

        self.executeTrakingOfPayment(item.id);
        
      }

      //******** MASCOTA *******
      // else if(item.category.id == 9){
      //   /*Operación por Default. Se utiliza únicamente para envair un email tanto al agente de sabar
      //   como al usuario*/
      //   let url = APIServices.product_payment + item.id + "/";
      //   let payload = {};
      //   await self.$http
      //     .patch(url, payload)
      //     .then(() => {
      //       // console.log("Respuesta del server", result);
      //       // console.log("Mensaje: ", result.data.extra_data.final_response.message);
      //     })
      //     .catch(err => {
      //       // console.log(err)
      //       if (err.response) {
      //         self.showAPIErrors = true;
      //         self.ApiErrorMessages = err.response.data.messages.errors;
      //       }
      //     })
      //     .finally(() => {
      //       // console.log("Cerrando");
      //       self.shoppingCar.getQuoteWithDetails();
      //       window.close();
      //     });        
      // }

      //******** CONTINENTAL - ACCIDENTES EN VIAJES *******
      // else if (item.category.id == 12) {
      //   self.showPaymentModal = true;
      //   // console.log("ITEM: " + JSON.stringify(item));
      //   self.shoppingCar.generatePaymentIntent(item).then(response => {
      //     self.stripePaymentObject = response.data;
      //     // console.log(
      //     //   "Respuesta de payment intent: ",
      //     //   JSON.stringify(self.stripePaymentObject)
      //     // );

          
      //   });
      //   self.executeTrakingOfPayment(item.id);
      // }
      // //VRIM
      // else if (item.category.id == 13) {
      //   self.showPaymentModal = true;
      //   self.shoppingCar.generatePaymentIntent(item).then(response => {
      //     self.stripePaymentObject = response.data;
      //     // console.log(
      //     //   "Respuesta de payment intent: ",
      //     //   self.stripePaymentObject
      //     // );
      //   });
      //   self.executeTrakingOfPayment(item.id);
      // }
      
      //******** CUALQUIER OTRO SEGURO *******
      // else {
      //   console.log("entre aqui: " + item.category.id);
      //   Operación por Default. Se utiliza únicamente para envair un email tanto al agente de sabar
      //   como al usuario*//*
      //   let url = APIServices.product_payment + item.id + "/";
      //   let payload = {};
      //   await self.$http
      //     .patch(url, payload)
      //     .then(result => {
      //       console.log("Respuesta del server", result);
      //       console.log("Mensaje: ", result.data.extra_data.final_response.message);
      //     })
      //     .catch(err => {
      //       console.log(err);
      //     })
      //     .finally(() => {
      //       console.log("Cerrando");
      //       self.shoppingCar.getQuoteWithDetails();
      //       window.close();
      //     });
      // }

      // this.showLoader = false;
    
    },




    async openRequerimetsModal(item) {
      // console.log(item)
      this.showModal = !this.showModal;
      this.currentItemRequirements = item;
      // console.log(
      //   "API de selección de aseguradora: ",
      //   JSON.stringify(this.currentItemRequirements)
      // );
    },
    confirmedRequirements() {
      this.showModal = false;
      this.setInitialState(); // Reload data by changes
    },
    executeTrakingOfPayment(quoteCategoryId) {
      let self = this;
      // console.log(
      //   "self.trackingPaymentIds: ",
      //   JSON.stringify(self.trackingPaymentIds)
      // );
      if (!self.trackingPaymentIds.includes(quoteCategoryId)) {
        self.trackingPaymentIds.push(quoteCategoryId);
      }

      if (!self.trackingPaymentInstance) {
        self.trackingPaymentInstance = setInterval(
          async function KeepTrackingLoadProcess() {
            try {
              let pendingIds = [];
              console.log("self.shoppingCar", self.shoppingCar);
              self.shoppingCar.getQuoteWithDetails();
              self.trackingPaymentIds.forEach(itemId => {
                let quoteCategoryTraked = self.shopingList.filter(
                  item => item.id == itemId
                )[0];
                if (!["PAYD", "PYST"].includes(quoteCategoryTraked.step.id)) {
                  pendingIds.push(itemId);
                }
              });
              self.trackingPaymentIds = pendingIds;
              if (self.trackingPaymentIds.length == 0) {
                clearInterval(self.trackingPaymentInstance);
              }
            } catch (error) {
              clearInterval(self.trackingPaymentInstance);
            }
          },
          5000
        );
      }
    },

    getPackageSelected(item_id){

      var carItems = this.shoppingCar.carItems
      var insurance_data = carItems.filter((item) => item.id == item_id)[0]
      var package_selected_id = insurance_data.packageSelectedId
      var package_selected = insurance_data.products.filter((item) => item.id == package_selected_id)[0]
      // console.log(package_selected)
      // console.log(package_selected.policy_premium)


      return package_selected
    },

    test(){
      this.$swal({ icon:"success" , title:"Buen Trabajo!" , text:"Se ha enviado correctamente el url de pago al Cliente" , timer:3000 , showConfirmButton: false });
    }

  }
};
</script>

<style >
[disabled] {
  opacity: 0.7;
}

select {
  max-width: 200px !important;
}

.card_background_package {
  background-color: rgba(173, 216, 230, 0.582);
  border-radius: 10px 10px 0px 0px;
}

.bg-light-gray{
  background-color:rgba(128, 128, 128, 0.075);
}

.btn_url_payment{
  position: absolute;
  bottom:0;
  left:0;
  left:0;
  height: 1.5rem;
  width: 1.5rem;
  z-index: 1000;
  background-color: #7faa41;
  cursor: none;
}

</style>