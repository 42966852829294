<template>
  <div class="w-20 h-20 rounded-full border-4 flex items-center justify-center text-center text-xl mx-auto font-sans leading-4" :class="[statusVariationClass]">
    {{ countDown > 0 ? timer: "0s" }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      countDown: 120,
      initial: 120,
    };
  },
  computed: {
    statusVariationClass() {
      let classes = "";
      if (this.countDown >= this.initial/2) {
        classes = "border-primary"
      } else if (this.countDown >= this.initial/4) {
        classes = "border-tertiary"
      } else if (this.countDown > 0) {
        classes = "border-danger"
      } else {
        classes = "border-red-800 bg-red-100 text-red-800 "
      }
      // console.log(this.countDown);
      return classes
    },
    timer() {
      var minute = Math.floor((this.countDown / 60) % 60);
      minute = (minute < 10)? '0' + minute : minute;
      var second = this.countDown % 60;
      second = (second < 10)? '0' + second : second;
      return minute + ':' + second;
    }
  },
  methods: {
    restart() {
      this.countDown = 120
      this.countDownTimer();
    },
    countDownTimer() {
      let self = this;
      if (self.countDown > 0) {
        setTimeout(() => {
          self.countDown -= 1;
          self.countDownTimer();
        }, 1000);
      }
    },
  },
  created() {
    this.countDownTimer();
  },
};
</script>