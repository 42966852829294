<template>
  <div>
    <form
      @submit.prevent="onSubmit"
      class="w-full flex flex-wrap justify-center"
    >
      <div class="container">
        <div class="text-2xl sm:text-3xl md:text-4xl font-extrabold flex">
          <h2 class="t-title-2">
            {{ item.product.name }}
          </h2>
        </div>
        <div class="text-secondary mb-5 rounded-md px-5 y-2">
          <p class="t-alert success">
            Usaremos esta información para generar el seguro, es necesario que
            revises los requisitos y que confirmes que la información es
            correcta.
          </p>
          <p class="t-alert success">
            Después de confirmar, no se podrá realizar ningún cambio.
          </p>
        </div>
        <div class="w-full px-5">
          <div class="t-form-section">
            <div class="t-section-title py-0">Datos de cuenta</div>
            <div class="t-section-content py-5">
              <div class="t-input-group">
                <label for="email" class="t-label">Email</label>
                <div class="flex-1 cursor-not-allowed">
                  <div class="w-100 text-left t-form">
                    {{ baseData.email }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadedData" class="w-full px-5">
          <div class="t-form-section">
            <div class="t-section-title py-0">Datos personales</div>
            <div class="t-section-content py-5">
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      $v.baseData.name && !$v.baseData.name.$invalid || !$v.baseData.name
                    )
                  "
                  :text="'Nombre'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.name.$model"
                    type="text"
                    placeholder="Nombre"
                    class="t-form"
                    :class="[
                      $v.baseData.name.$invalid && showErrors ? 'invalid' : '',
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.name.required && showErrors"
                  >
                    Nombre requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      $v.baseData.first_name && !$v.baseData.first_name.$invalid || !$v.baseData.first_name
                    )
                  "
                  :text="'Apellido paterno'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.first_name.$model"
                    type="text"
                    placeholder="Apellido paterno"
                    class="t-form"
                    :class="[
                      $v.baseData.first_name.$invalid && showErrors
                        ? 'invalid'
                        : '',
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.first_name.required && showErrors"
                  >
                    Apellido requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      $v.baseData.last_name && !$v.baseData.last_name.$invalid || !$v.baseData.last_name
                    )
                  "
                  :text="'A. Materno'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.last_name.$model"
                    type="text"
                    placeholder="Apellido materno"
                    class="t-form"
                    :class="[
                      $v.baseData.last_name.$invalid && showErrors
                        ? 'invalid'
                        : '',
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.last_name.required && showErrors"
                  >
                    Apellido requerido
                  </div>
                </div>
              </div>





              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      $v.baseData.phone_number && !$v.baseData.phone_number.$invalid || !$v.baseData.phone_number
                    )
                  "
                  :text="'Celular'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.phone_number.$model"
                    placeholder="Celular"
                    type = "number"
                    minlength = "10"
                    maxlength="10"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"


                    class="t-form"
                    :class="[
                      $v.baseData.phone_number.$invalid && showErrors
                        ? 'invalid'
                        : '',
                      ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.phone_number.required && showErrors"
                  >
                    Número teléfonico requerido
                  </div>
                </div>
              </div> 



              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      $v.baseData.born_date && !$v.baseData.born_date.$invalid || !$v.baseData.born_date
                    )
                  "
                  :text="'Fecha de nacimiento'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.born_date.$model"
                    type="date"
                    maxlength="3"
                    placeholder="3 carácteres alfanumericos"
                    class="t-form"
                    :class="[
                      $v.baseData.born_date.$invalid && showErrors
                        ? 'invalid'
                        : '',
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="showErrors && !$v.baseData.born_date.required"
                  >
                    Requerido.
                  </div>
                  <div
                    class="t-form-error"
                    v-if="showErrors && !$v.baseData.born_date.isMinValidAge"
                  >
                    Debes ser mayor de 18 años para comprar un seguro.
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      $v.baseData.gender && !$v.baseData.gender.$invalid || !$v.baseData.gender
                    )
                  "
                  :text="'Sexo'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <c-autocomplete
                    v-model.trim="$v.baseData.gender.$model"
                    api_resource="gender"
                    class="t-form"
                    :data="[
                      { id: 1, name: 'Masculino' },
                      { id: 2, name: 'Femenino' },
                      { id: 3, name: 'Otro' },
                    ]"
                    :httpConnector="$http"
                  ></c-autocomplete>
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.gender.id.required && showErrors"
                  >
                    Género requerido
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Despliega errores provenientes de las APIs -->
        <div v-if="showAPIErrors" class="w-full flex flex-wrap justify-end">
          <div class="t-form-error block">
            <ul v-for="(error, index) in ApiErrorMessages" :key="index">
              <li>{{ error.title }}</li>
            </ul>
          </div>
        </div>

        <div class="w-full flex flex-wrap justify-end mt-5 mb-10">
          <div
            v-if="showErrors && $v.baseData.$invalid"
            class="
              w-full
              rounded-md
              px-5
              py-2
              border-2 border-red-700
              bg-red-50
              text-red-700
              my-3
            "
          >
            <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"
              class="fa-lg"
            />
            Algunos campos requieren de tu atención antes de poder continuar.
          </div>
          <button type="submit" id="scrollStartEl" class="bg-special-3 h-10 px-10 rounded-md text-primary text-2xl w-full sm:w-auto">
            Confirmar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>

import { mapActions,mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { isMinValidAge } from "../../constants/config";
import AutocompleteSelectForm from "../../components/FormsGenerator/Forms/AutocompleteSelectForm";
import { scrollTo } from "../../utils/index";
import { minLength,maxLength } from "vuelidate/lib/validators";

//Obtiene el regex del rfc de constants para validarlo
//const rfc = helpers.regex("rfc",rfcRegex);

export default {
  data: function () {
    return {
      showErrors: false,
      showAPIErrors: false,
      ApiErrorMessages: [],
      loadedData: false,
      baseData: {},
      extraData: {
        phone_number: "",
        economic_activity: {
          id: null,
          name: "",
        },
      },

      formDataOptions: {
        gender: [],
        civil_status: [],
        country: [],
        zip_code: [],
        state: [],
        town_council: [],
        colony: [],
      },
    };
  },
  validations() {
    let validations = {

      baseData: {
        phone_number: {
          required,
          minLengthValue: minLength(10),
          maxLengthValue: maxLength(10)
        },
        name: {
          required
        },
        first_name: {
          required
        },
        last_name: {
          required
        },
        num_ext: {
          required
        },
        born_date: {
          required,
          isMinValidAge
        },
        gender: {
          id: { required }
        }
      },
    };
    return validations;
  },
  computed: {
    ...mapGetters(["loggedIn","userData"]),
  },
  components: {
  "c-autocomplete": AutocompleteSelectForm,
  },
  props: {
    data: {},
    itemId: {},
    item: {},
    lastQuoteData: {},
  },
  mounted() {

    let self = this;
    self.baseData = { ...self.data, ...self.extraData };
    // console.log('Datos del baseData')
    // console.log(self.baseData)
    // console.log('self.data')
    // console.log(self.data)
    // console.log('self extraData')
    // console.log(self.extraData)
    self.baseData.phone_number = self.userData.phone_number
    self.loadedData = true;
    self.getGender().then((response) => {
      self.formDataOptions.gender = [
        { id: null, name: "Selecciona un valor" },
        ...response,
      ];
    });
    self.getCivilStatus().then((response) => {
      self.formDataOptions.civil_status = [
        { id: null, name: "Selecciona un valor" },
        ...response,
      ];
    });
  },
  methods: {
    ...mapActions([
      "getGender",
      "getCivilStatus",
      "getZipCode",
      "fillProductRequirements",
    ]),
    scrollToFirstError() {
      setTimeout(() => {
        let duration = 300;
        let parentEl = document.querySelectorAll(".scrolling-touch")[0];
        let targetEl = document.querySelector(".t-form-error");
        if (targetEl) {
          scrollTo(duration, parentEl, targetEl);
        }
      }, 50);
    },
    formatDate(date) {
      if (typeof date == "string") {
        try {
          date = new Date(date);
        } catch (error) {
          date = new Date();
        }
      }
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      day = ("0" + day).slice(-2);
      month = ("0" + month).slice(-2);
      return `${year}-${month}-${day}`;
    },
    async refreshAddressInfo() {
      let self = this;
      let payload = {
        params: {
          full_data: true,
        },
        id: self.baseData.zip_code.id,
      };
      await self.getZipCode(payload).then((response) => {
        self.fillDefaultDataForm(response);
      });
    },
    onSubmit() {
      let self = this
      self.$emit("processing", true);
      let payload = {
        id: this.itemId,
        data: {
          ...this.baseData
        }
      };

      self.$v.$touch();

      if (self.$v.$invalid) {
        // console.log('Invalid')
        self.showErrors = true;
        self.scrollToFirstError();
        self.$emit("processing", false);
      } else {
        // console.log('Valid')
        self.showErrors = false;
        self
          .fillProductRequirements(payload)
          .then((result) => {
            self.firstResponse = result;
            self.$emit("save", result);
            self.$emit("processing", false);
          })
          .catch(function(error) {
            //Quita el loader ...
            self.$emit("processing", false);
            if (error.response) {
              self.showAPIErrors = true;
              self.ApiErrorMessages = error.response.data.messages.errors;
            }
          });
      }
      
    }
  }
};
</script>