<template>
  <div>
    <form
      @submit.prevent="onSubmit"
      class="w-full flex flex-wrap"
    >
      <div class="text-secondary mb-5 rounded-md px-5 y-2">
        <p class="text-justify mb-2">
          Este seguro no requiere de información adicional
        </p>
      </div>
      <div class="w-full px-5">
        <div class="w-full flex flex-wrap items-center relative mb-8 sm:mb-3">
          <div class="text-xl text-left w-full py-5">
            <b>Datos de cuenta</b>
          </div>
          <div class="col text-right mb-3">
            <label
              for="name"
              class="
                absolute
                left-1
                -top-6
                leading-5
                text-left
                flex
                items-end
                sm:relative
                sm:left-0
                sm:top-0
              "
              >Email</label
            >
            <div class="flex-1 max-w-full">
              <div>
                {{ baseData.email }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Despliega errores provenientes de las APIs -->
      <div v-if="showAPIErrors" class="w-full flex flex-wrap justify-end">
        <div class="t-form-error block">
          <ul v-for="(error, index) in ApiErrorMessages" :key="index">
            <li>{{ error.title }}</li>
          </ul>
        </div>
      </div>
      <div class="w-full flex justify-end mt-5">
        <button
          type="submit"
          class="
            bg-special-3
            h-10
            px-10
            rounded-md
            text-primary text-2xl
            w-full
            sm:w-auto
          "
        >
          Confirmar
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data: function () {
    return {
      showErrors: false,
      loadedData: false,
      showAPIErrors: false,
      ApiErrorMessages: [],
      baseData: {},
      firstResponse: {},
      formDataOptions: {
        gender: [],
        civil_status: [],
        country: [],
        zip_code: [],
        state: [],
        town_council: [],
        colony: [],
      },
    };
  },
  components: {
  },
  props: {
    data: {},
    itemId: {},
    lastQuoteData: {},
  },
  mounted() {
    let self = this;
    self.baseData = { ...self.data };
    self.loadedData = true;
    self.setInitialState();
  },
  methods: {
    ...mapActions([
      "getGender",
      "getCivilStatus",
      "getZipCode",
      "fillProductRequirements",
    ]),
    async setInitialState() {
      this.onSubmit();
    },
    onSubmit() {
      let self = this;
      this.$emit("processing", true);
      
      let payload = {
        id: this.itemId,
        data: {
          ...this.baseData,
        },
      };
      self.showErrors = false;
      self
        .fillProductRequirements(payload)
        .then((result) => {
          self.firstResponse = result;
          self.$emit("save", result);
          self.$emit("processing", false);
          self.$notify({
            group: 'foo',
            type: "success",
            title: 'Este seguro no tiene requerimientos adicionales',
            text: 'Los requerimientos ya están cubiertos, puedes continuar con el pago.',
            duration: 10000
          })
        })
        .catch(function(error) {
          //Quita el loader ...
          self.$emit("processing", false);
          if (error.response) {
            self.showAPIErrors = true;
            self.ApiErrorMessages = error.response.data.messages.errors;
          }
        });
    },
  },
};
</script>