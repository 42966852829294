<template>
<div>
  <form @submit.prevent="onSubmit" class="w-full flex flex-wrap justify-center">
    <div class="container">
      <div class="text-2xl sm:text-3xl md:text-4xl font-extrabold flex">
        <h2 class="t-title-2">
          {{ item.product.name }}
        </h2>
      </div>
      <div class="text-secondary mb-5 rounded-md px-5 y-2">
        <p class="t-alert success">
          Usaremos esta información para generar el seguro, es necesario que
          revises los requisitos y que confirmes que la información es correcta.
        </p>
        <p class="t-alert success">
          Después de confirmar, no se podrá realizar ningún cambio.
        </p>
      </div>
      <div class="w-full px-5">
        <div class="t-form-section">
          <div class="t-section-title py-0">Datos de cuenta</div>
          <div class="t-section-content py-5">
            <div class="t-input-group">
              <label for="email" class="t-label">Email</label>
              <div class="flex-1 cursor-not-allowed">
                <div class="w-100 text-left t-form">
                  {{ baseData.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loadedData" class="w-full px-5">
        <div class="t-form-section">
          <div class="t-section-title py-0">Datos Personales</div>
          <div class="t-section-content py-5">

            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="Boolean($v.baseData.occupation.$model)"
                :text="'¿Cuál es tu Ocupación?'"
                :required="true"
              />
              <div class="flex-1 max-w-full">
                  
                <v-select
                  v-model.trim="$v.baseData.occupation.$model"
                  :options="occupations"
                  index="id"
                  label="name"
                  class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                >
                
                </v-select>
                
                <div
                  class="t-form-error"
                  v-if="!$v.baseData.occupation.$model && showErrors"
                >
                  La ocupación es obligatoria.
                </div>
              </div>
            </div>

            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="Boolean($v.baseData.nationality.$model)"
                :text="'¿Cuál es tu Nacionalidad?'"
                :required="true"
              />
              <div class="flex-1 max-w-full">
                <v-select
                  v-model.trim="$v.baseData.nationality.$model"
                  :options="nationalities"
                  index="id"
                  label="name"
                  class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                >
                </v-select>
                
                <div
                  class="t-form-error"
                  v-if="!$v.baseData.nationality.$model && showErrors"
                >
                  La nacionalidad es obligatoria.
                </div>
              </div>
            </div>

            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean($v.baseData.birth_country.$model)
                "
                :text="'¿Cuál es tu país de Nacimiento?'"
                :required="true"
              />
              <div class="flex-1 max-w-full">

                <v-select
                  ref="focusForm"
                  v-model.trim="$v.baseData.birth_country.$model"
                  :options="countries"

                  index="id"
                  return-object
                  label="name"
                  class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                >

                </v-select>

                <div
                  class="t-form-error"
                  v-if="!$v.baseData.birth_country.$model && showErrors"
                >
                  El país de nacimiento es obligatorio.
                </div>
              </div>
            </div>

            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean($v.baseData.fiscal_regime.$model)
                "
                :text="'¿Cuál es tu Régimen Fiscal?'"
                :required="true"
              />
              <div class="flex-1 max-w-full">

                <v-select
                  ref="focusForm"
                  v-model.trim="$v.baseData.fiscal_regime.$model"
                  :options="listRegimeFiscal"

                  index="id"
                  return-object
                  label="name"
                  class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                >
                </v-select>

                <div
                  class="t-form-error"
                  v-if="!$v.baseData.fiscal_regime.$model && showErrors"
                >
                  El Regimen Fiscal es obligatorio.
                </div>
              </div>
            </div>

            <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean($v.baseData.CFDI_use.$model)
                "
                :text="'¿Cuál es tu Uso de CFDI?'"
                :required="true"
              />
              <div class="flex-1 max-w-full">

                <v-select
                  ref="focusForm"

                  v-model.trim="$v.baseData.CFDI_use.$model"
                  :options="list_cfdi_use"

                  index="id"
                  return-object
                  label="name"
                  class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                >
                </v-select>

                <div
                  class="t-form-error"
                  v-if="!$v.baseData.CFDI_use.$model && showErrors"
                >
                  El uso de CFDI es obligatorio.
                </div>
              </div>
            </div>



            <!-- <div class="t-input-group">
              <t-label
                class="t-label"
                :showStatus="showErrors"
                :valid="
                  Boolean($v.baseData.rf.$model)
                "
                :text="'¿Requieres Fáctura?'"
                :required="false"
              />
              <div class="flex-1 max-w-full">

                <v-select
                  ref="focusForm"

                  v-model.trim="$v.baseData.rf.$model"
                  :options="['Sí','No']"
                  index="id"
                  return-object
                  label="name"
                  class="w-full text-black t-form rounded-md border-2 text-xl md:text-2xl"                            
                >
                </v-select>

                <div
                  class="t-form-error"
                  v-if="!$v.baseData.rf.$model && showErrors"
                >
                </div>
              </div>
            </div> -->





            <!-- <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.ppe && !$v.baseData.ppe.$invalid) ||
                      !$v.baseData.ppe
                  )
                "
                :text="'¿Eres una persona politicamente expuesta?'"
                :required="true"
              />
              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.ppe.$model = 'Sí'"
                >
                  <label for="">Sí</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.ppe.$model"
                    name="pet_type"
                    value="Sí"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.ppe.$model = 'No'"
                >
                  <label for="">No</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.ppe.$model"
                    name="ppe_type"
                    value="No"
                  />
                </div>
              </div>

              <div
                v-if="showErrors && !$v.baseData.ppe.required"
                class="t-form-error"
              >
                Necesitamos saber esta información.
              </div>

              <div
                v-else-if="showErrors && !$v.baseData.ppe.validate_ppe"
                class="t-form-error"
              >
                Lamentablemente nuestras políticas no nos permiten brindar un seguro para personas politicamente expuestas.
              </div>
            </div> -->



            <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                Boolean(
                  ($v.baseData.autorization_reception_docs && !$v.baseData.autorization_reception_docs.$invalid) ||
                  !$v.baseData.autorization_reception_docs
                )
                "
                :text="'¿Deseas recibir documentación contractual de forma electrónica?'"
                :required="true"
              />
              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.autorization_reception_docs.$model = 'Sí'"
                >
                  <label for="">Sí</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.autorization_reception_docs.$model"
                    name="ard_type"
                    value="Sí"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.autorization_reception_docs.$model = 'No'"
                >
                  <label for="">No</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.autorization_reception_docs.$model"
                    name="ard_type"
                    value="No"
                  />
                </div>
              </div>
              
              <div
                v-if="showErrors && !$v.baseData.autorization_reception_docs.required"
                class="t-form-error"
              >
                Necesitamos saber esta información.
              </div>
              <div
                v-else-if = "showErrors && !$v.baseData.autorization_reception_docs.validate_ard"
                class="t-form-error"
              >
                Esta autorización es requerida para continuar.
              </div>
            </div>



            <!-- <div class="t-input-group">
              <t-label
                class="t-label text-justify"
                :showStatus="showErrors"
                :valid="
                  Boolean(
                    ($v.baseData.acp &&
                      !$v.baseData.acp.$invalid) ||
                      !$v.baseData.acp
                  )"
                :text="'¿Actuá por cuenta propia?'"
                :required="true"
              />
              <div class="t-radio-group-wraper">
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.acp.$model = '1'"
                >
                  <label for="">Sí</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.acp.$model"
                    name="acp_gender"
                    value="Sí"
                    selected
                  />
                </div>
                <div
                  class="t-radio-group py-0"
                  @click="$v.baseData.acp.$model = '2'"
                >
                  <label for="">No</label>
                  <input
                    type="radio"
                    v-model.trim="$v.baseData.acp.$model"
                    name="acp"
                    value="No"
                  />
                </div>
              </div>
              <div
                v-if="showErrors && !$v.baseData.acp.required"
                class="t-form-error"
              >
                Necesitamos saber esta información.
              </div>
            </div> -->

          </div>
        </div>

<!-- 
          <div class="w-full">
            <div class="w-full">
              <div class="t-form-section">
                <h2 class="t-section-title py-0">Dirección</h2>
                <div class="t-section-content py-5">
                  <div class="t-input-group">
                    <label for="name" class="t-label">C.P.</label>
                    <div class="flex-1 relative">
                      <v-select
                        type="text"
                        class="t-form h-max-10"
                        v-model="baseData.zip_code"
                        :options="baseDataOptions.zip_codes"
                        @input="refreshAddressInfo"
                        index="id"
                        label="name"
                        @search="onSearchCP"
                        return-object
                      >
                        <template slot="option" slot-scope="option">
                          <div class="d-center">
                            {{ option.name }}
                          </div>
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          <div class="selected d-center">
                            {{ option.name }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">País</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="baseData.country"
                        :options="[{ id: 1, name: 'México' }]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Estado</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="baseData.state"
                        :options="[baseData.state]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Municipio</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="baseData.town_council"
                        :options="[baseData.town_council]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>
                  <div class="t-input-group">
                    <label for="name" class="t-label">Colonia</label>
                    <div class="flex-1 relative">
                      <v-select
                        disabled
                        type="text"
                        class="t-form h-max-10"
                        v-model="baseData.colony"
                        :options="[baseData.colony]"
                        index="id"
                        label="name"
                        return-object
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="w-full flex flex-wrap">
                    <div
                      class="
                        w-6/12
                        text-right
                        flex flex-wrap
                        justify-end
                        items-start
                        align-end
                        relative
                        mb-8
                        sm:mb-3
                      "
                    >
                      <label for="name" class="t-label">Calle</label>
                      <div class="flex-1 relative">
                        <input
                          v-model="baseData.street"
                          type="text"
                          class="t-form"
                          :class="[
                            $v.baseData.street.$invalid ? 'invalid' : '',
                          ]"
                        />
                      </div>
                    </div>
                    <div
                      class="
                        w-3/12
                        text-right
                        flex flex-wrap
                        justify-end
                        items-start
                        align-end
                        relative
                        mb-8
                        sm:mb-3
                      "
                    >
                      <label for="name" class="t-label">#Ext</label>
                      <div class="flex-1 relative">
                        <input
                          v-model="baseData.num_ext"
                          type="text"
                          class="t-form"
                          :class="[
                            $v.baseData.num_ext.$invalid ? 'invalid' : '',
                          ]"
                        />
                      </div>
                    </div>
                    <div
                      class="
                        w-3/12
                        text-right
                        flex flex-wrap
                        justify-end
                        items-start
                        align-end
                        relative
                        mb-8
                        sm:mb-3
                      "
                    >
                      <label for="name" class="t-label">#Int</label>
                      <div class="flex-1 relative">
                        <input
                          v-model="baseData.num_int"
                          type="text"
                          class="t-form"
                          :class="[
                            $v.baseData.num_int.$invalid ? 'invalid' : '',
                          ]"
                        />
                      </div>
                    </div>
                    <div class="w-full">
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.baseData.num_ext.required"
                      >
                        Número exterior requerido
                      </div>
                      <div
                        class="t-form-error"
                        v-if="showErrors && !$v.baseData.street.maxLength"
                      >
                        Máximo
                        {{ $v.baseData.street.$params.maxLength.max }}
                        carácteres
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->







      </div>









      <div class="w-full flex flex-wrap justify-end mt-5 mb-10">
        <div
          v-if="showErrors && $v.baseData.$invalid"
          class="
            w-full
            rounded-md
            px-5
            py-2
            border-2 border-red-700
            bg-red-50
            text-red-700
            my-3
          "
        >
          <font-awesome-icon
            :icon="['fas', 'exclamation-triangle']"
            class="fa-lg"
          />
          Algunos campos requieren de tu atención antes de poder continuar.
        </div>



          <!-- Despliega errores provenientes de las APIs -->
          <div v-if="showAPIErrors" class="w-full flex flex-wrap justify-end">
            <div class="t-form-error block">
              <ul v-for="(error, index) in ApiErrorMessages" :key="index">
                <li>{{ error.title }}</li>
              </ul>
            </div>
          </div>



        <button
          type="button"
          class="bg-danger text-white px-5 py-1 rounded-md"
          @click="$emit('close')"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="
            bg-special-3
            h-10
            px-10
            rounded-md
            text-primary-dark text-xl
            w-full
            sm:w-auto
          "
        >
          Confirmar
        </button>
      </div>
    </div>
  </form>

</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { required ,sameAs } from "vuelidate/lib/validators";
import { scrollTo } from "../../utils/index";

export default {
  data: function() {
    return {
      showErrors : false,
      showAPIErrors: false,
      loadedData : false,
      
      baseData : {
        /* Extra user data for address*/
        country: { id: 1, name: "México" },
        zip_code: { id: null, name: "Selecciona un valor" },
        state: { id: null, name: "Selecciona un valor" },
        town_council: { id: null, name: "Selecciona un valor" },
        colony: { id: null, name: "Selecciona un valor" },
        street: "",
        num_ext: "",
        num_int: "",
        address_id: null
      },

      formData : {
        ppe : "",
        autorization_reception_docs : "",
        acp : 1,

        // Billing Data
        require_billing: "",
        occupation: "",
        nationality: "",
        birth_country: "",
        fiscal_regime: "",
        CFDI_use: "",

        // Address Data
        colony: "",
        town_council: "",
        state: ""
      },

      //? Billing Data   
      require_billing: "",
      occupation: "",   
      nationality: "",
      birth_country: "",
      fiscal_regime: "",
      CFDI_use: "",
      selectedCountries:"",
      selectedCFDI:"",

      // Address Data
      colony: "",
      town_council: "",
      state: "",

      
      countries: [],
      selectedNationalities:"",
      occupations: [],
      nationalities:[],
      listRegimeFiscal:[],
      list_cfdi_use:[],



      formDataOptions: {
        gender: [],
        civil_status: [],
        country: [],
        zip_code: [],
        state: [],
        town_council: [],
        colony: []
      },

      // Load Countries
      selectedValue: null,
      canContinue: true,
      responseOptions: [],
      selected: null,
      timeout: null,
      optionList: [],

    };
  },
  props: {
    data: {},
    item: {},
    itemId: {},
    lastQuoteData: {},

  },
  validations() {
    return {
      baseData: {
       //? Billing Data
        require_billing : {},
        occupation : {
          required
        },
        nationality : {
          required
        },
        birth_country : {
          required
        },
        fiscal_regime : {
          required
        },
        CFDI_use : {
          required
        },
        autorization_reception_docs : {
          required,
          validate_ard: sameAs(() => {
            return "Sí";
          })
        },
        // Address Data
        colony: {},
        town_council: {},
        state: {}
      }
    };
  },
  computed: {
      ...mapGetters(["user", "userData", "loggedIn", "shoppingCar"])
  },
  mounted() {
    let self = this;
    //console.log(self.user)
    //console.log(self.userData)
    // console.log("userData_PhoneNumber:" + self.userData.phone_number)
    // console.log("selfBaseDataPhoneNumber" + self.baseData.phone_number)
    this.$emit("processing", false);
    self.baseData = { ...self.data, ...self.formData };
    // console.log(self.baseData)
    self.loadedData = true;
    self.selectedValue = self.value ? self.value.value : null;

    self.baseData.email = self.userData.email;
    self.baseData.require_billing = self.userData.require_billing

    var personal_billing_expedition = self.userData.personal_billing_expedition
    var personal_data_fiscal = self.userData.personal_data_fiscal
    var external_data_fiscal = self.userData.external_data_fiscal

    self.baseData.phone_number = self.userData.phone_number
    self.baseData.rfc = self.userData.rfc
    self.baseData.homoclave = self.userData.homoclave

    if(personal_billing_expedition){
      self.baseData.occupation = personal_data_fiscal["fiscal_data"]["ocupation"]
      self.baseData.nationality = personal_data_fiscal["fiscal_data"]["nationality"]
      self.baseData.birth_country = personal_data_fiscal["fiscal_data"]["birth_country"]
      self.baseData.fiscal_regime = personal_data_fiscal["fiscal_data"]["fiscal_regime"]
      self.baseData.CFDI_use = personal_data_fiscal["fiscal_data"]["cfdi_use"]
    } else {
      self.baseData.occupation = external_data_fiscal["fiscal_data"]["ocupation"]
      self.baseData.nationality = external_data_fiscal["fiscal_data"]["nationality"]
      self.baseData.birth_country = external_data_fiscal["fiscal_data"]["birth_country"]
      self.baseData.fiscal_regime = external_data_fiscal["fiscal_data"]["fiscal_regime"]
      self.baseData.CFDI_use = external_data_fiscal["fiscal_data"]["cfdi_use"]
    }

    // Address Data
    self.baseData.colony = self.userData.user_address[0].colony,
    self.baseData.town_council = self.userData.user_address[0].town_council,
    self.baseData.state = self.userData.user_address[0].state

    
    //? DENTEGRA CATALOGS
    this.$http
    .get(this.$APIEndpoint + this.$APIServices.catalog_fiscal_dentegra)
    .then((response) => {

      var catalogs = response.data
      catalogs.forEach(catalog => {

        var catalog_name = catalog["name"]
        var catalog_json = catalog["json"]

        switch(catalog_name){
          
          case "Ocupaciones":
            catalog_json.forEach(catalog_json_object => {
              // var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.occupations.push(value)
            });
          break;
          case "Nacionalidades":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.nationalities.push(`(${value}) ${name}`)           
            });
          break;
          case "Países":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.countries.push(`(${value}) ${name}`)
            });
          break;
          case "Regimenes_Fiscales":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.listRegimeFiscal.push(`(${value}) ${name}`)
            });
          break;
          case "Uso_CFDI":
            catalog_json.forEach(catalog_json_object => {
              var name = catalog_json_object["name"]
              var value = catalog_json_object["value"]
              this.list_cfdi_use.push(`(${value}) ${name}`)
            });
          break;
        }

      });

    });

  },
  methods: {
    ...mapActions(["getGender", "getCivilStatus", "fillProductRequirements"]),
    scrollToFirstError() {
      setTimeout(() => {
        let duration = 300;
        let parentEl = document.querySelectorAll(".scrolling-touch")[0];
        let targetEl = document.querySelector(".t-form-error");
        if (targetEl) {
          scrollTo(duration, parentEl, targetEl);
        }
      }, 50);
    },
  

    onSubmit() {
      let auxData = {
        ...this.data,
        ...this.baseData
      };

      let payload = {
        id: this.itemId,
        data: auxData
      };
      
      // console.log(this.baseData);

      let self = this;
      this.$emit("processing", true);
      self.$v.$touch();
      // console.log(self.$v.$invalid)
      if (self.$v.$invalid) {
        self.showErrors = true;
        self.scrollToFirstError();
        self.$emit("processing", false);
      } else {
        //console.log('Valid')
        //console.log(payload)
        self.showErrors = false;
          this.fillProductRequirements(payload).then(result => {
          self.$emit("processing", false);
          this.$emit("save", result);
        }).catch(function(error) {
          //Quita el loader ...
          self.$emit("processing", false);
          if (error.response) {
            self.showAPIErrors = true;
            self.ApiErrorMessages = error.response.data.messages.errors;
          }
        });

      }
    },
 
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous", this.question);
    },
    gotoNext() {
      let self = this;
      if (self.required) {
        // console.log('Si es requerido')
        if (self.selectedValue) {
          self.$emit("next");
        } else {
          self.canContinue = false;
        }
      } else {
        self.$emit("next");
      }
    },
  },
  components: {
  }
};

</script>