<template>
  <div>
    <form
      @submit.prevent="onSubmit"
      class="w-full flex flex-wrap justify-center"
    >
      <div class="container">
        <div class="text-2xl sm:text-3xl md:text-4xl font-extrabold flex">
          <h2 class="t-title-2">
            {{ item.product.name }}
          </h2>
        </div>
        <div class="text-secondary mb-5 rounded-md px-5 y-2">
          <p class="t-alert success">
            Usaremos esta información para generar el seguro, es necesario que
            revises los requisitos y que confirmes que la información es
            correcta.
          </p>
          <p class="t-alert success">
            Después de confirmar, no se podrá realizar ningún cambio.
          </p>
        </div>
        <div class="w-full px-5">
          <div class="t-form-section">
            <div class="t-section-title py-0">Datos de cuenta</div>
            <div class="t-section-content py-5">
              <div class="t-input-group">
                <label for="email" class="t-label">Email</label>
                <div class="flex-1 cursor-not-allowed">
                  <div class="w-100 text-left t-form">
                    {{ baseData.email }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadedData" class="w-full px-5">
          <div class="t-form-section">
            <div class="t-section-title py-0">Contacto de Emergencia</div>
            <div class="t-section-content py-5">
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.name && !$v.baseData.name.$invalid) ||
                        !$v.baseData.name
                    )
                  "
                  :text="'Nombre'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.name.$model"
                    type="text"
                    placeholder="Nombre"
                    class="t-form"
                    :class="[
                      $v.baseData.name.$invalid && showErrors ? 'invalid' : ''
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.name.required && showErrors"
                  >
                    Nombre requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.first_name &&
                        !$v.baseData.first_name.$invalid) ||
                        !$v.baseData.first_name
                    )
                  "
                  :text="'Apellido paterno'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.first_name.$model"
                    type="text"
                    placeholder="Apellido paterno"
                    class="t-form"
                    :class="[
                      $v.baseData.first_name.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.first_name.required && showErrors"
                  >
                    Apellido requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.last_name &&
                        !$v.baseData.last_name.$invalid) ||
                        !$v.baseData.last_name
                    )
                  "
                  :text="'A. Materno'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.last_name.$model"
                    type="text"
                    placeholder="Apellido materno"
                    class="t-form"
                    :class="[
                      $v.baseData.last_name.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.last_name.required && showErrors"
                  >
                    Apellido requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.phone_number &&
                        !$v.baseData.phone_number.$invalid) ||
                        !$v.baseData.phone_number
                    )
                  "
                  :text="'Teléfono'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.phone_number.$model"
                    placeholder="Escribe 10 números"
                    type = "number"
                    minlength = "10"
                    maxlength="10"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    class="t-form"
                    :class="[
                      $v.baseData.phone_number.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.phone_number.required && showErrors"
                  >
                    Número teléfonico requerido
                  </div>
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.phone_number.numeric && showErrors"
                  >
                    Digita únicamente números.
                  </div>
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.phone_number.minLengthValue && showErrors || !$v.baseData.phone_number.maxLengthValue && showErrors"
                  >
                    Sólo digita 10 números sin espacios.
                  </div>                  
                </div>
              </div>
              <!--
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.born_date &&
                        !$v.baseData.born_date.$invalid) ||
                        !$v.baseData.born_date
                    )
                  "
                  :text="'Fecha de nacimiento'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.born_date.$model"
                    type="date"
                    class="t-form"
                    :class="[
                      $v.baseData.born_date.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="showErrors && !$v.baseData.born_date.required"
                  >
                    Fecha requerida.
                  </div>
                  <div
                    class="t-form-error"
                    v-if="showErrors && !$v.baseData.born_date.isMinValidAge"
                  >
                    Debes ser mayor de 18 años para comprar un seguro.
                  </div>
                </div>
              </div>
              -->
              <!--
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.passport &&
                        !$v.baseData.passport.$invalid) ||
                        !$v.baseData.passport
                    )
                  "
                  :text="'Pasaporte'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <input
                    v-model.trim="$v.baseData.passport.$model"
                    type="text"
                    placeholder="Pasaporte"
                    class="t-form"
                    :class="[
                      $v.baseData.passport.$invalid && showErrors
                        ? 'invalid'
                        : ''
                    ]"
                  />
                  <div
                    class="t-form-error"
                    v-if="showErrors && !$v.baseData.passport.required"
                  >
                    Pasaporte requerido.
                  </div>
                </div>
              </div>
              -->
              <!--
                <div class="flex-1">
                  <label><input type="checkbox" id="cbox1" v-model="checked" true-value="yes" false-value="no" @click="validaCheck"> Yo soy uno de los pasajeros</label>
                </div>
              -->
            </div>
          </div>
        </div>
        <div v-if="loadedData" class="w-full px-5">
          <div class="t-form-section">
            <div class="t-section-title py-0">Datos del viaje</div>
            <div class="t-section-content py-5">
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.country_origin_of_travel &&
                        !$v.baseData.country_origin_of_travel.$invalid) ||
                        !$v.baseData.country_origin_of_travel
                    )
                  "
                  :text="'País de salida'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <c-autocomplete
                    v-model="$v.baseData.country_origin_of_travel.$model"
                    api_resource="catalog/continental-origins"
                    class="t-form"
                    :data="[$v.baseData.country_origin_of_travel.$model]"
                    :httpConnector="$http"
                  ></c-autocomplete>
                  <div
                    class="t-form-error"
                    v-if="
                      !$v.baseData.country_origin_of_travel.id.required &&
                        showErrors
                    "
                  >
                    País de salida del viaje requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.type_of_travel &&
                        !$v.baseData.type_of_travel.$invalid) ||
                        !$v.baseData.type_of_travel
                    )
                  "
                  :text="'Tipo de viaje'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <c-autocomplete
                    v-model="$v.baseData.type_of_travel.$model"
                    api_resource="catalog/continental-destinies"
                    class="t-form"
                    :data="[$v.baseData.type_of_travel.$model]"
                    :httpConnector="$http"
                  ></c-autocomplete>
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.type_of_travel.id.required && showErrors"
                  >
                    Tipo de viaje requerido
                  </div>
                </div>
              </div>
              <div class="t-input-group">
                <t-label
                  class="t-label"
                  :showStatus="showErrors"
                  :valid="
                    Boolean(
                      ($v.baseData.dates_travel &&
                        !$v.baseData.dates_travel.$invalid) ||
                        !$v.baseData.dates_travel
                    )
                  "
                  :text="'Fechas del viaje: salida y vuelta'"
                  :required="true"
                />
                <div class="flex-1 max-w-full">
                  <t-datepicker
                    v-model="baseData.dates_travel"
                    :minDate="new Date()"
                    :required="true"
                    :range="true"
                    :date-formatter="dateFormatter"
                    :date-parser="dateParser"
                    :inline="true"
                    :readonly="true"
                    date-format="YYYY-MM-DD"
                    user-format="DD/MM/YYYY"
                    class="border-2 border-primary rounded-md text-primary text-xl bg-purple-50 w-96"
                  />
                  <!--:disabled-dates="disabledDates"-->
                  <div
                    class="t-form-error"
                    v-if="!$v.baseData.name.required && showErrors"
                  >
                    Fechas del viaje necesarias
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="loadedData" class="w-full px-5">
          <div class="t-form-section">
            <div class="t-section-title py-0">Datos de los viajeros</div>
            <div v-if="travelers_info.length" class="t-section-content py-5">
              <div
                v-for="(traveler, index) in $v.travelers_info.$each.$iter"
                :key="index"
                class="rounded-xl my-2 p-5 border-2 border-primary"
              >
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.nombre && !traveler.nombre.$invalid) ||
                          !traveler.nombre
                      )
                    "
                    :text="'Nombre'"
                    :required="true"
                  />
                  <div class="flex-1 max-w-full">
                    <input
                      v-model.trim="traveler.nombre.$model"
                      type="text"
                      placeholder="Nombre"
                      class="t-form"
                      :class="[
                        traveler.nombre.$invalid && showErrors ? 'invalid' : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="!traveler.nombre.required && showErrors"
                    >
                      Nombre requerido
                    </div>
                  </div>
                </div>
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.apellido && !traveler.apellido.$invalid) ||
                          !traveler.apellido
                      )
                    "
                    :text="'Primer apellido'"
                    :required="true"
                  />
                  <div class="flex-1 max-w-full">
                    <input
                      v-model.trim="traveler.apellido.$model"
                      type="text"
                      placeholder="Apellido paterno"
                      class="t-form"
                      :class="[
                        traveler.apellido.$invalid && showErrors
                          ? 'invalid'
                          : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="!traveler.apellido.required && showErrors"
                    >
                      Nombre requerido
                    </div>
                  </div>
                </div>
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.fechaNac && !traveler.fechaNac.$invalid) ||
                          !traveler.fechaNac
                      )
                    "
                    :text="'Fecha de nacimiento'"
                    :required="true"
                  />
                  <!--:disabled-dates="[getFunctionInvalidDate(traveler.edad)]"-->
                  <div class="flex-1 max-w-full">
                    <t-datepicker
                      v-model="traveler.fechaNac.$model"
                      :required="true"
                      :date-formatter="dateFormatter"
                      :date-parser="dateParser"
                      date-format="YYYY-MM-DD"
                      user-format="DD/MM/YYYY"
                      class="border border-primary text-primary text-xl bg-purple-50 w-full"
                      :class="[
                        traveler.fechaNac.$invalid && showErrors
                          ? 'invalid'
                          : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="showErrors && !traveler.fechaNac.required"
                    >
                      Campo requerido.
                    </div>
                    <div
                      class="t-form-error"
                      v-if="
                        showErrors &&
                          baseData.travelers_info[index].fechaNac_validation.invalid
                      "
                    >
                      La fecha de nacimiento de no corresponde con la edad
                      especificada.
                    </div>
                  </div>
                </div>
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.edad && !traveler.edad.$invalid) ||
                          !traveler.edad
                      )
                    "
                    :text="'Edad'"
                    :required="true"
                  />
                  <div class="flex-1 max-w-full">
                    <input
                      v-model.trim="traveler.edad.$model"
                      type="text"
                      class="t-form"
                      :class="[
                        traveler.edad.$invalid && showErrors ? 'invalid' : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="showErrors && !traveler.edad.required"
                    >
                      Campo requerido.
                    </div>
                    <div
                      class="t-form-error"
                      v-if="showErrors && !traveler.fechaNac.integer"
                    >
                      Sólo se permiten números.
                    </div>
                  </div>
                </div>
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.pasaporte && !traveler.pasaporte.$invalid) ||
                          !traveler.pasaporte
                      )
                    "
                    :text="'Pasaporte'"
                    :required="true"
                  />
                  <div class="flex-1 max-w-full">
                    <input
                      v-model.trim="traveler.pasaporte.$model"
                      type="text"
                      class="t-form"
                      :class="[
                        traveler.pasaporte.$invalid && showErrors
                          ? 'invalid'
                          : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="showErrors && !traveler.pasaporte.required"
                    >
                      Campo requerido.
                    </div>
                  </div>
                </div>
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.email && !traveler.email.$invalid) ||
                          !traveler.email
                      )
                    "
                    :text="'Email'"
                    :required="true"
                  />
                  <div class="flex-1 max-w-full">
                    <input
                      v-model.trim="traveler.email.$model"
                      type="text"
                      class="t-form"
                      :class="[
                        traveler.email.$invalid && showErrors ? 'invalid' : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="showErrors && !traveler.email.required"
                    >
                      Campo requerido.
                    </div>
                  </div>
                </div>
                <div class="t-input-group">
                  <t-label
                    class="t-label"
                    :showStatus="showErrors"
                    :valid="
                      Boolean(
                        (traveler.telefono && !traveler.telefono.$invalid) ||
                          !traveler.telefono
                      )
                    "
                    :text="'Teléfono'"
                    :required="true"
                  />
                  <div class="flex-1 max-w-full">
                    <input
                      v-model.trim="traveler.telefono.$model"
                      type="text"
                      class="t-form"
                      :class="[
                        traveler.telefono.$invalid && showErrors
                          ? 'invalid'
                          : ''
                      ]"
                    />
                    <div
                      class="t-form-error"
                      v-if="showErrors && !traveler.telefono.required"
                    >
                      Campo requerido.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Despliega errores provenientes de las APIs -->
        <div v-if="showAPIErrors" class="w-full flex flex-wrap justify-end">
          <div class="t-form-error block">
            <ul v-for="(error, index) in ApiErrorMessages" :key="index">
              <li>{{ error.title }}</li>
            </ul>
          </div>
        </div>

        <div class="w-full flex flex-wrap justify-end mt-5 mb-10">
          <div
            v-if="showErrors && $v.baseData.$invalid"
            class="
              w-full
              rounded-md
              px-5
              py-2
              border-2 border-red-700
              bg-red-50
              text-red-700
              my-3
            "
          >
            <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"
              class="fa-lg"
            />
            Algunos campos requieren de tu atención antes de poder continuar.
          </div>
          <button
            type="submit"
            class="
              bg-special-3
              h-10
              px-10
              rounded-md
              text-primary text-2xl
              w-full
              sm:w-auto
            "
          >
            Confirmar
          </button>
        </div>

      </div>
    </form>
  </div>
</template>
<script>
import { mapActions,mapGetters } from "vuex";
import { required, email , integer, numeric, minLength, maxLength} from "vuelidate/lib/validators";
import AutocompleteSelectForm from "../../components/FormsGenerator/Forms/AutocompleteSelectForm";
import moment from "moment";
//import { isMinValidAge } from "../../constants/config";
// import { getFirstArrayValue } from '../../utils/index'
import { scrollTo } from "../../utils/index";
//import Checkbox from '../../components/FormsGenerator/Forms/Checkbox.vue';

//Obtiene el regex del rfc de constants para validarlo
//const rfc = helpers.regex("rfc",rfcRegex);

export default {
  data: function() {
    return {
      showErrors: false,
      showAPIErrors: false,
      ApiErrorMessages: [],
      loadedData: false,
      checked: false,
      baseData: {},
      prevQuestions: {},
      disabledDates: [
        function() {
          return true;
        }
      ],
      extraData: {
        phone_number: "",
        passport: "",
        travelers_info: []
      },
      formDataOptions: {
        civil_status: [],
        country: [],
        zip_code: [],
        state: [],
        town_council: [],
        colony: []
      }
    };
  },
  validations() {
    let validations = {
      baseData: {
        phone_number: {
          required,
          numeric,
          minLengthValue: minLength(10),
          maxLengthValue: maxLength(10)
        },
        name: {
          required
        },
        first_name: {
          required
        },
        last_name: {
          required
        },/*
        born_date: {
          required,
          isMinValidAge
        },
        passport: {
          required
        },*/
        dates_travel: {
          required
        },
        country_origin_of_travel: {
          id: { required }
        },
        type_of_travel: {
          id: { required }
        },
        travelers_info: {
          required
        }
      },
      travelers_info: {
        $each: {
          nombre: {
            required
          },
          apellido: {
            required
          },
          fechaNac: {
            required
          },
          edad: {
            required,
            integer
          },
          pasaporte: {
            required
          },
          email: {
            required,
            email
          },
          telefono: {
            required
          }
        }
      },
      validationGroup: ["baseData", "travelers_info"]
    };
    return validations;
  },
  components: {
    "c-autocomplete": AutocompleteSelectForm
    //"checkbox": Checkbox
  },
  props: {
    data: {},
    itemId: {},
    item: {},
    lastQuoteData: {}
  },
  mounted() {
    let self = this;
    self.baseData = { ...self.data, ...self.prevQuestions, ...self.extraData };
    self.baseData.phone_number = self.userData.phone_number
    self.travelers_info = [];
    self.baseData.travelers_ages.forEach(age => {
      self.travelers_info.push({
        nombre: "",
        apellido: "",
        fechaNac: moment()
          .subtract(parseInt(age) + 1, "years")
          .toDate(),
        edad: age,
        pasaporte: "",
        email: "",
        telefono: "",
        beneficios_adicionales: [],
        fechaNac_validation: {
          invalid: false
        }
      });
    });

    self.baseData.travelers_info = self.travelers_info;
    self.loadedData = true;
    self.setInitialState();
  },
  computed: {
    ...mapGetters(["loggedIn","userData"]),
  },
  methods: {
    ...mapActions(["fillProductRequirements"]),
    scrollToFirstError() {
      setTimeout(() => {
        let duration = 300;
        let parentEl = document.querySelectorAll(".scrolling-touch")[0];
        let targetEl = document.querySelector(".t-form-error");
        if (targetEl) {
          scrollTo(duration, parentEl, targetEl);
        }
      }, 50);
    },/*
    startCallBack: function(x) {
      console.log(x);
    },
    endCallBack: function(x) {
      console.log(x);
    },*/
    dateFormatter(date, format) {
      return moment(date).format(format);
    },
    dateParser(date, format) {
      return moment(date, format).toDate();
    },
    async setInitialState() {
      let self = this;
      let zip_code = self.baseData.zip_code;
      /*
      if (zip_code) {
        let payload = {
          params: {
            full_data: true
          },
          id: zip_code.id
        };
        console.log(payload);
      } else {
        self.fillDefaultDataForm({});
      }*/
      if(!zip_code){
        self.fillDefaultDataForm({});
      }
    },
    setZipCodeOptions(data) {
      this.formDataOptions.zip_code = data;
    },
    formatDate(date) {
      if (typeof date == "string") {
        try {
          date = new Date(date);
        } catch (error) {
          date = new Date();
        }
      }
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      day = ("0" + day).slice(-2);
      month = ("0" + month).slice(-2);
      //console.log("date", `${year}-${month}-${day}`);
      return `${day}/${month}/${year}`;
    },
    async refreshAddressInfo() {
      let self = this;
      let payload = {
        params: {
          full_data: true
        },
        id: self.baseData.zip_code.id
      };
      await self.getZipCode(payload).then(response => {
        self.fillDefaultDataForm(response);
      });
    },
    validateTravelerAge(traveler) {
      let calculated_age = moment().diff(traveler.fechaNac, "years", true);
      if (parseInt(calculated_age) == parseInt(traveler.edad)) {
        return true;
      } else {
        return false;
      }
    },
    onSubmit() {
      let self = this;
      this.$emit("processing", true);
      this.showErrors = false;
      this.showAPIErrors = false;

      let payload = {
        id: this.itemId,
        data: {
          ...this.baseData
        }
      };

      self.$v.$touch();
      //Valida errores en las fechas de nacimiento de los pasajeros
      self.baseData.travelers_info.forEach(function(part, index, theArray) {
        let traveler = theArray[index];
        self.showErrors = false;
        if (!self.validateTravelerAge(traveler)) {
          self.showErrors = true;
          theArray[index].fechaNac_validation.invalid = true;
        } else {
          theArray[index].fechaNac_validation.invalid = false;
        }
      });

      if (self.$v.$invalid) {
        self.showErrors = true;
        self.scrollToFirstError();
        self.$emit("processing", false);
      } else {
        self.showErrors = false;
        self
          .fillProductRequirements(payload)
          .then(result => {
            self.$emit("save", result);
            self.$emit("processing", false);
          })
          .catch(function(error) {
            self.$emit("processing", false);
            if (error.response) {
              self.showAPIErrors = true;
              self.ApiErrorMessages = error.response.data.messages.errors;
            }
          });
      }
    },
    validaCheck() {
      if (this.checked === "yes") {
        //Copiar todos los datos ene l primer traveler
      } else {
        //limpiar todos los datos del primer traveler
      }
    }
  }
};
</script>
