export const addCommas = nStr => {
  nStr += ''
  var x = nStr.split('.')
  var x1 = x[0]
  var x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}

export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key]
    var B = b[key]
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1
    } else {
      return -1
    }
  })
  return array
}

export const getDateWithFormat = () => {
  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + '.' + mm + '.' + yyyy
}

export const getDateHumanized = () => {
  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "mayo", "junio",
    "July", "August", "September", "October", "November", "December"
  ];

  const today = new Date()
  let dd = today.getDate()
  let mm = today.getMonth() + 1 // January is 0!

  var yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return dd + ' de ' + monthNames[mm - 1] + ' de ' + yyyy
}

export const getFirstArrayValue = (variable) => {
  if (Array.isArray(variable)) {
    return variable;
  } else if (typeof variable === 'object' && variable !== null) {
    let arrayObjectValues = Object.values(variable);
    for( let i = 0; i < arrayObjectValues.length; i++) {
      let auxFinded = getFirstArrayValue(arrayObjectValues[i]);
      if (Array.isArray(auxFinded)) {
        return auxFinded
      }
    }
    return []
  }
  return null
}

export const getCurrentTime = () => {
  const now = new Date()
  return now.getHours() + ':' + now.getMinutes()
}

export const scrollTo = (duration, parentEl, targetEl) => {
  let el = parentEl;
  let parentOffset = el.offsetTop;
  let start = el.scrollTop;
  let end = targetEl.offsetTop - parentOffset;
  // console.log(start, end);
  animateValue(parentEl, start, end - 150, duration);
}

export const animateValue = (obj, start, end, duration) => {
  let startTimestamp = null;
  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    obj.scrollTop = Math.floor(progress * (end - start) + start);
    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}